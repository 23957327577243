import {
  propertyFolderMaskDataNotRelevant,
  transformPropertyFolderDataForDisplay
} from '../../display-transformations';
import { DefaultTimeZone, FormCode, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { summariseAddressesOrTitles, summariseContractSettlement } from '../../../../yjs-schema/property';
import { calculateFinalPrice2 } from '../../sections/contract-price';
import * as PdfGen from '../../index';
import { getPrimaryContact } from '../../../dataExtract';
import { FIRST_PAGE_MARGIN, SUBSEQUENT_PAGE_MARGIN } from '../../measurements';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { getDocumentTitle, timestampOfAgreement } from '../../index';
import { formatTimestamp } from '../../../formatting';
import { reaformsLogo, transparentPixel } from '../../logos';
import { getFormInstance } from './saa-cover-page';
import { GetterBinder } from '../../../../types/PDFDefinition';
import { FormTypes } from '../../../../yjs-schema/property/form';

export function generatePdf({
  agencyLogoImage,
  title,
  property,
  propertyCover,
  finalPriceDisplay,
  brand,
  useCoverPage,
  agreementDateText
}: {
  agencyLogoImage: string,
  title: string,
  propertyCover: string,
  property: MaterialisedPropertyData,
  finalPriceDisplay: any | undefined, // Note that this is now purchase price, without GST if not included #8897
  brand: EntityBrandFormConfig,
  useCoverPage: boolean,
  agreementDateText: string
}) {
  const settlementText = summariseContractSettlement(property) || 'No settlement options were selected, refer document';
  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    images: {
      agencyLogo: agencyLogoImage || transparentPixel
    },
    content: [
      PdfGen.coverPage(
        title,
        [
          { label: 'Property', value: propertyCover || '' },
          { label: 'Vendor (primary)', value: getPrimaryContact(property?.vendors, property?.primaryVendor)?.fullLegalName },
          { label: 'Purchaser (primary)', value: getPrimaryContact(property?.purchasers, property?.primaryPurchaser)?.fullLegalName },
          { label: 'Purchase Price', value: finalPriceDisplay },
          {
            label: 'Settlement', value: settlementText
          },
          { label: 'Salesperson', value: property?.agent?.[0]?.salesp?.[0]?.name }
        ],
        brand)
    ],
    pageMargins: (currentPage: number) => {
      const pageNum = useCoverPage ? currentPage - 1 : currentPage;
      return pageNum > 1 ? SUBSEQUENT_PAGE_MARGIN : FIRST_PAGE_MARGIN;
    },
    footer: (currentPage: number, pageCount: number) => PdfGen.standardFooter(
      currentPage,
      title,
      useCoverPage,
      pageCount,
      false,
      `Controlled Fully signed on ${agreementDateText}`
    ),
    header: (currentPage: number) => PdfGen.standardHeader(currentPage, title, useCoverPage, reaformsLogo, brand.agencyContact)
  });
}

export function prepareData({
  dataBinder,
  metaBinder,
  formCode,
  formId,
  completedAtMs,
  agencyLogoImage,
  brand
}: {
  dataBinder: GetterBinder<MaterialisedPropertyData>;
  metaBinder: GetterBinder<TransactionMetaData>;
  formCode: string;
  formId: string;
  completedAtMs: number;
  brand: EntityBrandFormConfig;
  agencyLogoImage: string;
}) {
  const useCoverPage = true;
  if (!(dataBinder && metaBinder)) {
    throw new Error('Cannot generate preview, data binders are not initialised');
  }

  const propertyRaw = propertyFolderMaskDataNotRelevant(dataBinder.get());
  const meta = metaBinder.get();
  const formMetadata = getFormInstance(formCode, formId, meta);

  if (!formMetadata) {
    throw new Error('Cannot generate preview for nonexistent form');
  }

  const property = transformPropertyFolderDataForDisplay(propertyRaw) as MaterialisedPropertyData;
  const title = formCode === FormCode.RSC_ContractOfSale ? getDocumentTitle('Contract of Sale', property.landType) : FormTypes[formCode].label;
  const propertyCover = summariseAddressesOrTitles(property);
  const timeZone = formMetadata.signing?.sessionInitiator?.timeZone || DefaultTimeZone;

  const { purchasePriceDisplay } = calculateFinalPrice2(property?.vendorGst, property?.contractPrice);

  const agreementMs = timestampOfAgreement(completedAtMs, formMetadata.signing?.parties);
  const agreementDateText = formatTimestamp(agreementMs, timeZone, false);

  return {
    useCoverPage,
    property,
    title,
    propertyCover,
    finalPriceDisplay: purchasePriceDisplay,
    agreementDateText,
    agencyLogoImage,
    brand
  };
}

export function salesContractCoverPage({
  dataBinder,
  metaBinder,
  formCode,
  formId,
  brand,
  completedAtMs,
  agencyLogoImage
}: {
  dataBinder: GetterBinder<MaterialisedPropertyData>,
  metaBinder: GetterBinder<TransactionMetaData>,
  formCode: string,
  formId: string,
  completedAtMs: number,
  brand: EntityBrandFormConfig,
  agencyLogoImage: string
}) {
  return generatePdf(
    prepareData({
      dataBinder,
      metaBinder,
      formCode,
      formId,
      completedAtMs,
      brand,
      agencyLogoImage
    }),
  );
}
