import { coolingOffOptsDocument, CoolingOffType } from '@property-folders/contract';
import { generateCheckboxRows } from '../index';
import { mapOptsForCheckbox } from '../display-transformations';

export const coolingOffSection = (coolingOff?: CoolingOffType) => {
  return [
    { text: 'COOLING OFF', bold: true, margin: [0,20,0,5] },
    generateCheckboxRows(mapOptsForCheckbox(coolingOffOptsDocument), [coolingOff?.purchaserRight||''], 1)
  ];
};
