import { EditFieldInfo, FormFields, StringifyFn, SubscriptionFormCode } from './types';
import { Predicate } from '../predicate';
import { propertySection } from '../util/pdfgen';
import { flattenPdfDefinitionToPlaintext } from './flatten-pdf-definition-to-plaintext';
import { PartyType, SigningAuthorityType, VendorParty } from '@property-folders/contract';
import { vendorDescription } from '../util/formatting/string-composites';
import { getContactsFromAuthorityParty } from '../util/digest-authority-party-details';

function delimitedStrings(partsRaw: string[]): string {
  const parts = [...new Set(partsRaw)];
  if (!parts.length) return '';
  if (parts.length === 1) return parts[0];

  const commaParts = parts.slice(0, -1);
  const finalPart = parts.at(-1);
  return `${commaParts.join(', ')} and ${finalPart}`;
}

type TextElement =
  | string
  | undefined;
type TextElements =
  | TextElement
  | TextElement[];

function delimitedTextElements<T>(items: T[] | undefined, selector: (t: T) => TextElements, logSample?: boolean): string {
  if (logSample && items) {
    console.log('sample', items[0]);
  }
  const parts = (items || [])
    .map(selector)
    .flat()
    .filter(Predicate.isNotNull);

  return delimitedStrings(parts);
}

function singleTextElement<T>(items: T[] | undefined, predicate: (t: T) => boolean, selector: (t: T) => TextElements): string {
  const first = (items || [])
    .find(predicate);

  if (!first) return '';

  const text = selector(first);
  return Array.isArray(text)
    ? text.filter(Predicate.isNotNull)[0] || ''
    : text || '';
}

/** Set primary ID if primaryOnly is false  */
function vendorToRepresentativeNames(v: VendorParty, primaryOnly?: boolean, furnishSpecialNames?: boolean, primaryId?: string): TextElements {
  const contacts = getContactsFromAuthorityParty(v, v.id, { setPrimaryToFirst: (!primaryId || v.id !== primaryId) && !primaryOnly })
    .map(c=>{
      if (!furnishSpecialNames) return c;
      const rc = {
        ...c
      };
      if (c.partyType === PartyType.ExecutorJoint && rc.onBehalf.onBehalfOf) {
        rc.name = `${rc.name} as one of the Executors for the Estate of ${rc.onBehalf.onBehalfOf}`;
      }
      if (c.partyType === PartyType.AdministratorJoint && rc.onBehalf.onBehalfOf) {
        rc.name = `${rc.name} as one of the Administrators for the Estate of ${rc.onBehalf.onBehalfOf}`;
      }
      return rc;
    }); // For the purpose of the lookup, treat this vendor as primary, even if they're not. They would still be the primary of this party
  const primaryParty = contacts.find(c=>c.isPrimary)?.name;

  if (primaryOnly) return primaryParty ?? '';
  return [
    primaryParty,
    ...contacts.filter(c=>!c.isPrimary).map(c=>c.name??'')
  ].filter(Predicate.isTruthy);
}

function vendorToPrimaryContact(v: VendorParty) {
  const contact = getContactsFromAuthorityParty(v, v.id).filter(c=>c.isPrimary)[0];
  if (!contact) {
    throw new Error('No implcit primary was found');
  }
  return contact;
}

class Stringify {
  static noop: StringifyFn = () => '';
  static vendorNames: StringifyFn = data => delimitedTextElements(data.vendors, vendorDescription);
  static vendorOrTheirRepresentativeEmails: StringifyFn = data => delimitedTextElements(data.agent, a => a.form1?.serviceFaxOrEmail || a.email);
  static primaryVendorOrTheirRepresentativeName: StringifyFn = data => data.primaryVendor
    ? singleTextElement(data.vendors, v => v.id === data.primaryVendor, v => vendorToRepresentativeNames(v, true, true))
    : '';
  static primaryVendorOrAllVendorNames: StringifyFn = (data, meta) => data.allVendorsSignForm1
    ? Stringify.vendorNames(data, meta)
    : Stringify.primaryVendorOrTheirRepresentativeName(data, meta);
  static primaryVendorName: StringifyFn = data =>
    data.primaryVendor
      ? singleTextElement(data.vendors, v => v.id === data.primaryVendor, v => vendorToPrimaryContact(v).fullLegalName)
      : '';
  static vendorAddresses: StringifyFn = data => delimitedStrings([...new Set((data.vendors || [])
    .flatMap(v => v.addrSameAsSale
      ? (data.saleAddrs || []).map(addr => `${addr.streetAddr}, ${addr.subStateAndPost}`)
      : [v.addressSingleLine])
    .filter(Predicate.isNotNull)
  )]);
  static primaryVendorAddress: StringifyFn = (data, meta) => {
    if (!data.primaryVendor) return '';
    if (!data.vendors) return '';
    const primaryVendor = data.vendors.find(v => v.id === data.primaryVendor);
    if (!primaryVendor?.addrSameAsSale) {
      return primaryVendor?.addressSingleLine || '';
    }

    return this.firstPropertySaleAddress(data, meta);
  };
  static primaryOrAllVendorAddresses: StringifyFn = (data, meta) => data.allVendorsSignForm1
    ? Stringify.vendorAddresses(data, meta)
    : Stringify.primaryVendorAddress(data, meta);
  static vendorSalespersonNames: StringifyFn = data => delimitedTextElements((data.agent || []).flatMap(a => a.salesp || []), sp => sp.name);
  static vendorAgencyName: StringifyFn = data => delimitedTextElements((data.agent || []), a => a.form1?.company || a.company);
  static authorisedRepresentativeName: StringifyFn = (data, meta) => {
    const rep = data.authRep?.at(0);
    return rep
      ? rep.profileName
        ? rep.profileName
        : rep.company
      : Stringify.vendorSalespersonNames(data, meta);
  };
  static vendorAgentAddresses: StringifyFn = data => delimitedTextElements((data.agent || []), a => a.form1?.address || a.address);
  static vendorAgentServiceAddress: StringifyFn = data => delimitedTextElements((data.agent || []), a => a.form1?.serviceAddress || a.address);
  static purchaserNames: StringifyFn = data => delimitedTextElements(data.purchasers, p => p.fullLegalName);
  static primaryPurchaserAddress: StringifyFn = data => {
    if (!data.primaryPurchaser) return '';
    if (!data.purchasers) return '';
    return data.purchasers.find(p => p.id === data.primaryPurchaser)?.addressSingleLine || '';
  };
  static contractDate: StringifyFn = data => {
    switch (data.contractSettlement?.onDate) {
      case true:
        return data.contractSettlement.date || '';
      case false:
        return data.contractSettlement.afterCondition || '';
      default:
        return '';
    }
  };

  static propertySaleAddresses: StringifyFn = data => delimitedTextElements(data.saleAddrs, addr => `${addr.streetAddr}, ${addr.subStateAndPost}`);
  static firstPropertySaleAddress: StringifyFn = data => {
    if (!data.saleAddrs?.length) return '';
    const addr = data.saleAddrs[0];
    return `${addr.streetAddr}, ${addr.subStateAndPost}`;
  };
  static propertySaleTitles: StringifyFn = data => delimitedTextElements(data.saleTitles, title => title.title);
  static propertySaleDescription: StringifyFn = data =>
    flattenPdfDefinitionToPlaintext(propertySection({
      itemNo: 1,
      addresses: data.saleAddrs ?? [],
      titles: data.saleTitles ?? [],
      titleDivision: data.titleDivision ?? {}
    }))
      .replace('ITEM 1 – PROPERTY ', '');
}

const hiddenSignatureCounts: Record<string, EditFieldInfo | undefined> = {
  'DATA_SIGNATURECOUNT_BUYER': undefined,
  'DATA_SIGNATURECOUNT_LANDLORD': undefined,
  'DATA_SIGNATURECOUNT_LESSEE': undefined,
  'DATA_SIGNATURECOUNT_LESSOR': undefined,
  'DATA_SIGNATURECOUNT_PURCHASER_PAPERSIG': undefined,
  'DATA_SIGNATURECOUNT_PURCHASER': { editor: 'purchaser', stringify: data => (data.purchasers || []).length.toString() },
  'DATA_SIGNATURECOUNT_SELLER': undefined,
  'DATA_SIGNATURECOUNT_TENANT': undefined,
  'DATA_SIGNATURECOUNT_VENDOR_PAPERSIG': undefined,
  'DATA_SIGNATURECOUNT_VENDOR': { editor: 'vendor', stringify: data => (data.vendors || []).length.toString() }
};

export const dataMappingDefinitions: Record<SubscriptionFormCode, FormFields | undefined> = {
  [SubscriptionFormCode.SAR008_VendorQuestionnaire]: {
    fields: {
      'DATA_AVENDOR': { editor: 'vendor', stringify: Stringify.primaryVendorName },
      'DATA_APROPERTY': { editor: 'property', stringify: Stringify.propertySaleAddresses },
      'DATA_AACK_VENDOR_NAME': { editor: 'vendor', stringify: Stringify.primaryVendorOrTheirRepresentativeName },
      'DATA_AACK_VENDOR_NAME_REP': {
        editor: 'vendor', stringify: data => {
          const theVendor = 'the Vendor';
          const personRepresentative = 'person representing Vendor';
          if (!data.primaryVendor) return theVendor;
          const primaryVendor = (data.vendors || []).find(v => v.id === data.primaryVendor);

          return !primaryVendor?.authority || primaryVendor.authority === SigningAuthorityType.self
            ? theVendor
            : personRepresentative;
        }
      }
    }
  },
  [SubscriptionFormCode.SAR014_NoticeOfOfferToPurchaseResidentialLand]: undefined,
  [SubscriptionFormCode.SACS015_LicenceToOccupy]: {
    fields: {
      ...hiddenSignatureCounts,
      'DATA_BETWEEN_1': { editor: 'vendor', stringify: Stringify.vendorNames },
      'DATA_BETWEEN_2': { editor: 'purchaser', stringify: Stringify.purchaserNames },
      'DATA_CONTRACT_SETTLE': { editor: 'contract_settlement', stringify: Stringify.contractDate },
      'DATA_CONTRACT_PROPERTY': { editor: 'property', stringify: Stringify.propertySaleAddresses },
      'DATA_CONTRACT_CERTIFICATE': { editor: 'property', stringify: Stringify.propertySaleTitles }
    }
  },
  [SubscriptionFormCode.SAF001V2_Form1]: {
    // find a way to update these whenever things change, but don't present a way to edit them?
    // DATA_VENDOR_AGENT_CHECKBOX, DATA_PART_D_CHECKBOX
    fields: {
      ...hiddenSignatureCounts,
      'DATA_VENDOR': { editor: 'vendor', stringify: Stringify.vendorNames },
      'DATA_VENDOR_ADDRESS': { editor: 'vendor', stringify: Stringify.primaryVendorAddress },
      'DATA_VENDOR_AGENT': { editor: 'none', stringify: Stringify.vendorAgencyName },
      'DATA_VENDOR_AGENT_ADDRESS': { editor: 'none', stringify: Stringify.vendorAgentAddresses },
      'DATA_PURCHASER': { editor: 'none', disabled: true, stringify: ()=>'' },
      'DATA_PURCHASER_ADDRESS': { editor: 'none', disabled: true, stringify: ()=>'' },
      'DATA_DESCRIPTION_OF_THE_LAND': { editor: 'property', stringify: Stringify.propertySaleDescription },
      'DATA_METHODS_OF_SERVICE_B': { editor: 'vendor', stringify: Stringify.vendorAddresses },
      'DATA_METHODS_OF_SERVICE_C': { editor: 'none', stringify: Stringify.vendorOrTheirRepresentativeEmails },
      'DATA_METHODS_OF_SERVICE_D': { editor: 'none', stringify: Stringify.vendorAgentServiceAddress },
      'DATA_METHODS_OF_SERVICE_D_OPT': { editor: 'none', stringify: data => data.agent?.[0]?.form1?.serviceAddressIsRla ? '2' : '1' },
      'DATA_PART_C_ADDRESS': { editor: 'vendor', stringify: Stringify.primaryOrAllVendorAddresses },
      'DATA_PART_C_IWE': { editor: 'vendor', stringify: data => data.allVendorsSignForm1 ? '2' : '1' },
      'DATA_PART_C_VENDOR': { editor: 'vendor', stringify: Stringify.primaryVendorOrAllVendorNames },
      'DATA_PART_C_OPT': {
        editor: 'none', stringify: data => {
          switch ((data.vendors || []).find(vendor => vendor.id === data.primaryVendor)?.authority) {
            case SigningAuthorityType.self:
              // "vendor(s)"
              return '1';
            case undefined:
            case null:
              // "vendor(s) / person authorised to act on behalf of the vendor(s) in relation to the transaction"
              return '0';
            default:
              // "person authorised to act on behalf of the vendor(s) in relation to the transaction"
              return '2';
          }
        }
      },
      'DATA_PART_D_NAME': { editor: 'none', editorFiller: 'agent_or_representative',  stringify: Stringify.authorisedRepresentativeName },
      'DATA_PART_D_SIGNED': { editor: 'none', stringify: () => '3' },
      'DATA_DATEOFCONTRACT': { disabled: true, editor: 'none', stringify: () => '' }
    }
  }
};
