import { DocumentFieldType, PathSegments } from '../model';

export function generateVariationWatchFromFieldDefn(node: DocumentFieldType, parent: PathSegments = []): PathSegments[] {
  if (node._type === 'Array') {
    const currentPath = [...parent, '[*]'];
    return generateVariationWatchFromFieldDefn(node._children, currentPath);
  }
  if (node._type === 'Map') {
    return Object.entries(node)
      .filter(([key, _value]) => !key.startsWith('_'))
      .map(([key, valueNode]) => {
        const currentPath = [...parent, key];
        return generateVariationWatchFromFieldDefn(valueNode, currentPath);
      }).flat();
  }
  return [parent];
}