import { Predicate } from '../../../predicate';
import {
  agencyExclusiveOptions,
  boolYesNoOpts,
  commencementAgreementDateBoolOpts,
  getPreviousNoticeOptions,
  previousNoticeOptions
} from '../constants';
import {
  twoFieldTable,
  attachStackFirstItem,
  blankField,
  fieldLabel,
  fieldsSpacing,
  format,
  generateCheckboxRows,
  generateFieldTable,
  inlineBoolChecks,
  itemSection,
  itemSubsection,
  noborder,
  singleFieldTable,
  spaceStackLinesSideEffect,
  twoValueCheckbox
} from '..';
import { AgencyAgreementTerms, Agent } from '@property-folders/contract';
import { minimumFontSize } from '../constants';
import { mapOptsForCheckbox } from '../display-transformations';
import { FieldPlaceholderStyle } from '../standards';
import { BelongingEntityMeta } from '../../../redux-reducers/entityMeta';
import { companyTradingAs } from '../../formatting';
import { abnacnlabel } from '../../dataExtract';

type AgentDefn = Agent | {[param: string]: any, salesp: {[param: string]: any}[]};
type AgentNoSalespDefn = Omit<AgentDefn, 'salesp'>;

export function agentListing(
  agents: AgentDefn[],
  opts: {
    noSalesPIfNone?: boolean,
    dataModelPath?: string,
    otherBookmarks?: string[],
    isVariation?: boolean,

  } & ({
    primaryAgentListing: true,
    memberEntities: BelongingEntityMeta
  } | {
    primaryAgentListing?: false | undefined,
  })
) {
  const { dataModelPath = 'agent', isVariation, noSalesPIfNone, otherBookmarks = [], primaryAgentListing } = opts ?? {};
  let agentContent;
  if (agents.length === 0 || (agents.length === 1 && agents[0].salesp?.length === 0)) {
    agentContent = [{
      unbreakable: true,
      stack: spaceStackLinesSideEffect([
        singleFieldTable({ fieldName: 'Company', isVariation, fieldPlaceholder: FieldPlaceholderStyle.Name }),
        twoFieldTable({ fieldName: 'RLA', fieldName2: 'ABN', isVariation, fieldPlaceholder: FieldPlaceholderStyle.Rla, fieldPlaceholder2: FieldPlaceholderStyle.Abn }),
        twoFieldTable({ fieldName: 'Salesperson', fieldName2: 'Phone', isVariation, fieldPlaceholder: FieldPlaceholderStyle.Name, fieldPlaceholder2: FieldPlaceholderStyle.Mobile }),
        singleFieldTable({ fieldName: 'Email', isVariation, fieldPlaceholder: FieldPlaceholderStyle.Email })
      ])
    }];
  } else if (agents.length === 1 && agents[0].salesp?.length === 1 && !noSalesPIfNone) {
    const p = agents[0].salesp[0];
    const mainAgent = agents[0];
    const linkedAgency = opts.memberEntities[`${mainAgent?.linkedEntityId}`??''];
    agentContent = [{
      unbreakable: true,
      stack: spaceStackLinesSideEffect([
        singleFieldTable({
          fieldName: primaryAgentListing ? 'Agent legal name' : 'Agent name',
          fieldValue: primaryAgentListing && linkedAgency ? companyTradingAs(linkedAgency.name, linkedAgency.tradeName, { long: true }) :agents[0]?.company,
          isVariation,
          contentStyleOverride: 'content',
          fieldPlaceholder: FieldPlaceholderStyle.Name
        }),
        twoFieldTable({
          fieldName: 'Agent RLA',
          fieldValue: agents[0]?.rla,
          fieldPlaceholder: FieldPlaceholderStyle.Rla,
          fieldName2: `Agent ${abnacnlabel(agents[0]?.abn)}`,
          fieldValue2: agents[0]?.abn,
          fieldPlaceholder2: FieldPlaceholderStyle.Abn,
          isVariation,
          contentStyleOverride: 'content'
        }),
        twoFieldTable({
          fieldName: 'Salesperson name',
          fieldValue: p.name,
          fieldPlaceholder: FieldPlaceholderStyle.Name,
          fieldName2: 'Salesperson mobile',
          fieldValue2: p.phone,
          fieldPlaceholder2: FieldPlaceholderStyle.Mobile,
          isVariation,
          contentStyleOverride: 'content'
        }),
        singleFieldTable({
          fieldName: 'Salesperson email',
          fieldValue: p.email,
          fieldPlaceholder: FieldPlaceholderStyle.Email,
          isVariation,
          contentStyleOverride: 'content'
        })
      ])
    }];
  } else {
    agentContent = spaceStackLinesSideEffect(agents.map((agent, agentI) => {
      return [
        {
          unbreakable: true,
          stack: spaceStackLinesSideEffect([
            singleFieldTable({
              fieldName: 'Agent name',
              fieldValue: agent?.company,
              fieldPlaceholder: FieldPlaceholderStyle.Name,
              contentStyleOverride: 'content'
            }),
            twoFieldTable({
              fieldName: 'Agent RLA',
              fieldValue: agent?.rla,
              fieldPlaceholder: FieldPlaceholderStyle.Rla,
              fieldName2: `Agent ${abnacnlabel(agent?.abn)}`,
              fieldValue2: agent.abn,
              fieldPlaceholder2: FieldPlaceholderStyle.Abn,
              contentStyleOverride: 'content'
            })
          ])

        },
        {
          stack: spaceStackLinesSideEffect(((agent.salesp && agent.salesp.length > 0) || noSalesPIfNone ? (agent.salesp || []) : [{}] ).map(p => {
            return {
              unbreakable: true,
              stack: spaceStackLinesSideEffect([
                singleFieldTable({
                  fieldName: 'Salesperson name',
                  fieldValue: p.name,
                  fieldPlaceholder: FieldPlaceholderStyle.Name,
                  contentStyleOverride: 'content'
                }),
                twoFieldTable({
                  fieldName: 'Email',
                  fieldValue: p.email,
                  fieldPlaceholder: FieldPlaceholderStyle.Email,
                  fieldName2: 'Mobile',
                  fieldValue2: p.phone,
                  fieldPlaceholder2: FieldPlaceholderStyle.Mobile,
                  contentStyleOverride: 'content'
                })
              ])
            };
          }))
        }
      ];
    }).flat());
  }
  return itemSubsection({
    subsectionContent: agentContent,
    bookmark: [...otherBookmarks, `field_focus_${dataModelPath}`],
    isVariation
  });
}

export function agentNoSalespListing(
  agents: AgentNoSalespDefn[],
  dataModelPath = 'agent',
  otherBookmarks: string[] = [],
  isVariation?: boolean
) {
  let agentContent;
  if (agents.length === 0) {
    agentContent = [{
      unbreakable: true,
      stack: spaceStackLinesSideEffect([
        singleFieldTable({ fieldName: 'Company', isVariation, fieldPlaceholder: FieldPlaceholderStyle.Name }),
        twoFieldTable({ fieldName: 'RLA', fieldName2: 'ABN', isVariation, fieldPlaceholder: FieldPlaceholderStyle.Rla, fieldPlaceholder2: FieldPlaceholderStyle.Abn }),
        twoFieldTable({ fieldName: 'Salesperson', fieldName2: 'Phone', isVariation, fieldPlaceholder: FieldPlaceholderStyle.Name, fieldPlaceholder2: FieldPlaceholderStyle.Mobile }),
        singleFieldTable({ fieldName: 'Email', isVariation, fieldPlaceholder: FieldPlaceholderStyle.Email })
      ])
    }];
  } else if (agents.length === 1) {
    agentContent = [{
      unbreakable: true,
      stack: spaceStackLinesSideEffect([
        singleFieldTable({
          fieldName: 'Agent name',
          fieldValue: agents[0]?.company,
          isVariation,
          contentStyleOverride: 'content',
          fieldPlaceholder: FieldPlaceholderStyle.Name
        }),
        twoFieldTable({
          fieldName: 'Agent RLA',
          fieldValue: agents[0]?.rla,
          fieldPlaceholder: FieldPlaceholderStyle.Rla,
          fieldName2: `Agent ${abnacnlabel(agents[0]?.abn)}`,
          fieldValue2: agents[0]?.abn,
          fieldPlaceholder2: FieldPlaceholderStyle.Abn,
          isVariation,
          contentStyleOverride: 'content'
        })
      ])
    }];
  } else {
    agentContent = spaceStackLinesSideEffect(agents.map((agent, agentI) => {
      return [
        {
          unbreakable: true,
          stack: spaceStackLinesSideEffect([
            singleFieldTable({
              fieldName: 'Agent name',
              fieldValue: agent?.company,
              fieldPlaceholder: FieldPlaceholderStyle.Name,
              contentStyleOverride: 'content'
            }),
            twoFieldTable({
              fieldName: 'Agent RLA',
              fieldValue: agent?.rla,
              fieldPlaceholder: FieldPlaceholderStyle.Rla,
              fieldName2: `Agent ${abnacnlabel(agent.abn)}`,
              fieldValue2: agent.abn,
              fieldPlaceholder2: FieldPlaceholderStyle.Abn,
              contentStyleOverride: 'content'
            })
          ])

        }
      ];
    }).flat());
  }
  return itemSubsection({
    subsectionContent: agentContent,
    bookmark: [...otherBookmarks, `field_focus_${dataModelPath}`],
    isVariation
  });
}

export function agentSection(
  itemNo: number,
  agents: AgentDefn[],
  assocAuthorised: boolean | undefined,
  associatedAgents: AgentDefn[],
  isSole: boolean | undefined,
  agency: AgencyAgreementTerms | undefined,
  otherAgents: AgentDefn[] | undefined,
  prevAgentEnable: boolean | undefined,
  prevAgentConfirm: boolean | undefined,
  prevAgents: AgentDefn[] | undefined,
  prevAgentExpire: boolean | undefined,
  prevAgentNotice: string | undefined,
  agentAuthority: boolean | undefined,
  opts: {isVariation?: boolean, memberEntities: BelongingEntityMeta}
) {
  const { isVariation, memberEntities } = opts;
  const agentContent = [...agentListing(agents, { dataModelPath: 'agent', otherBookmarks: ['subsection-primary-agent'], isVariation, memberEntities, primaryAgentListing: true })];
  agentContent.push(...associatedAgentsSection(assocAuthorised, associatedAgents, isVariation));
  agentContent.push(...agencyTerm(isSole, agency?.duration, otherAgents, agency?.start, agency?.startOther, isVariation));
  agentContent.push(...previousAgents(prevAgentEnable, prevAgentConfirm, prevAgents, prevAgentExpire, prevAgentNotice, isVariation));
  agentContent.push(...agentAuthoritySection(agentAuthority, isVariation));

  return itemSection({
    itemNo: itemNo,
    itemTitleParam: 'Agent',
    bookmark: 'bookmark_agent',
    stackContent: agentContent,
    isVariation });
}

export function associatedAgentsSection(
  authorised: boolean | undefined,
  associatedAgents: AgentDefn[],
  isVariation?: boolean) {
  let subContent = [];
  if (typeof authorised === 'boolean') {
    const r: any[] = [
      {
        text: authorised
          ? [...format('The Agent is *authorised* and may appoint other licensed agents to assist the Agent in the sale process and marketing.')]
          : [...format('The Agent is *not authorised* to appoint other licensed agents to assist the Agent in the sale process and marketing.')],
        margin: [0,fieldsSpacing,0,fieldsSpacing],
        style: 'sectionText'
      }
    ];

    if (authorised) {
      if (!associatedAgents || associatedAgents.length === 0 || ((associatedAgents[0].company ?? '').trim() == '' && (associatedAgents[0].salesp?.[0]?.name?.trim() ?? '') == '')) {
        r.push(spaceStackLinesSideEffect([
          singleFieldTable({
            fieldName: 'In conjunction with',
            fieldValue: 'At the Agent’s discretion',
            fieldPlaceholder: FieldPlaceholderStyle.Name,
            contentStyleOverride: 'content'
          })
        ]));
      } else {
        r.push(spaceStackLinesSideEffect([
          singleFieldTable({
            fieldName: 'Agent name',
            fieldValue: associatedAgents[0]?.company,
            fieldPlaceholder: FieldPlaceholderStyle.Name,
            isVariation,
            contentStyleOverride: 'content'
          }),
          singleFieldTable({
            fieldName: 'Salesperson name',
            fieldValue: associatedAgents[0]?.salesp[0].name,
            fieldPlaceholder: FieldPlaceholderStyle.Name,
            isVariation,
            contentStyleOverride: 'content'
          }),
          twoFieldTable({
            fieldName: 'Agent RLA',
            fieldValue: associatedAgents[0]?.rla,
            fieldPlaceholder: FieldPlaceholderStyle.Rla,
            fieldName2: `Agent ${abnacnlabel(associatedAgents[0]?.abn)}`,
            fieldValue2: associatedAgents[0]?.abn,
            fieldPlaceholder2: FieldPlaceholderStyle.Abn,
            isVariation,
            contentStyleOverride: 'content'
          })
        ]));
      }
    }

    subContent = r;
  } else {
    subContent = [
      {
        text: [...format('The Agent is *authorised / not authorised* to appoint other licensed agents to assist the Agent in the sale process and marketing.')],
        margin: [0,0,0,fieldsSpacing],
        style: 'sectionText'
      },
      {
        stack: [
          singleFieldTable({ fieldName: 'In conjunction with', fieldValue: '', fieldPlaceholder: FieldPlaceholderStyle.Name })
        ]
      }
    ];
  }
  return itemSubsection({
    subsectionTitle: 'Associated agents',
    subsectionContent: subContent,
    bookmark: ['subsection-associated-agents', 'field_focus_authAssoc'],
    isVariation
  });
}
/**
 *
 * @param itemNo
 * @param isSole
 * @param duration Shall be a string as it will be transformed prior to arrival
 * @param otherAgents
 * @returns
 */
export function agencyTerm(
  isSole: boolean | undefined,
  duration: string | undefined,
  otherAgents: AgentDefn[] | undefined,
  commenceUponDateOfAgreement: boolean | undefined,
  commenceOtherDate: string | undefined,
  isVariation?: boolean) {
  const knownAgencyContent = Predicate.boolFalse(isSole) && (otherAgents || []).length > 0 && otherAgents?.[0].company
    ? attachStackFirstItem(agentListing(otherAgents, { noSalesPIfNone: true, dataModelPath: 'otherAgents', isVariation }),{
      text: 'Other general agencies if known',
      style: 'tableFieldLabel',
      margin: [0,0,0,fieldsSpacing]
    })
    : [];
  const termContent = [
    {
      unbreakable: true,
      stack: [
        { margin: [0, fieldsSpacing, 0, 0], text: '' },
        ...spaceStackLinesSideEffect([
          duration
            ? { text: [{ text: 'The duration of this Agreement is ', style: 'sectionText' }, { text: duration, style: 'fieldContent' }, { text: '.', style: 'sectionText' }] }
            : generateFieldTable(
              [
                {
                  border: noborder,
                  text: [
                    fieldLabel({ fieldName: 'The duration of this Agreement is', fieldColons: false, styleOverride: 'sectionText' }),
                    { text: '   90 days   ', style: 'fieldContent' },
                    { text: 'or:  ', style: 'sectionText' }
                  ]
                },
                blankField()
              ],
              ['auto', '*']
            ),
          { text: [
            'Notwithstanding the above, if the Vendor is a developer and the Property has or will be subdivided by the Vendor, the Term of the Agreement may exceed 90 days and continues until the Vendor provides 30 days notice of termination.'
          ] },
          {
            text: commenceUponDateOfAgreement === undefined ? 'This Agreement begins on the later of:' : 'This Agreement begins on:',
            style: 'tableFieldLabel',
            fontSize: isVariation ? minimumFontSize : undefined
          },
          generateCheckboxRows(
            mapOptsForCheckbox(commencementAgreementDateBoolOpts, commenceUponDateOfAgreement === false && commenceOtherDate),
            Predicate.isNotNullish(commenceUponDateOfAgreement) ? [commenceUponDateOfAgreement.toString()] : [],
            1
          )
        ].filter(Predicate.isTruthy))
      ]
    }

    ,
    {
      unbreakable: knownAgencyContent.length === 0,
      ...twoValueCheckbox({
        label: {
          text: [
            'This Agreement is a:'
          ],
          style: 'tableFieldLabel',
          fontSize: isVariation ? minimumFontSize : undefined
        },
        trueLabel: agencyExclusiveOptions.true,
        falseLabel: agencyExclusiveOptions.false,
        currentVal: isSole
      })
    }
  ];

  return itemSubsection({
    subsectionTitle: 'Term of Agreement',
    subsectionContent: spaceStackLinesSideEffect(termContent),
    unbreakable: false,
    bookmark: ['subsection-agency-term', 'field_focus_agency', 'field_focus_agencyExclusive'],
    isVariation
  });
}

function previousAgents(
  enable: boolean | undefined,
  confirmPrevious: boolean | undefined,
  prevAgents:  AgentNoSalespDefn[] | undefined,
  agencyExpired: boolean | undefined,
  notice: string | undefined,
  isVariation?: boolean) {

  const restContent = [];
  if (!Predicate.boolFalse(enable))  {
    !Predicate.boolFalse(confirmPrevious) && restContent.push({ // If the vendor has not given details, we shan't print them
      unbreakable: true,
      stack: spaceStackLinesSideEffect([
        {
          text: 'Vendor confirms the Property was previously listed for sale by:',
          style: 'sectionText',
          margin: [0, fieldsSpacing, 0, 0]
        },
        confirmPrevious && Array.isArray(prevAgents) && prevAgents.length > 0 && prevAgents[0].company
          ? agentNoSalespListing(prevAgents, 'prevAgents', [], isVariation)
          : singleFieldTable({
            fieldName: 'Agent:',
            fieldValue: '',
            fieldPlaceholder: FieldPlaceholderStyle.Name,
            fieldColons: false,
            isVariation
          })
      ])
    });
    const terminationDeets = [
      { text: [{
        text: 'Agency agreement expired?    ',
        style: 'tableFieldLabel',
        fontSize: isVariation ? minimumFontSize : undefined
      }, { text: inlineBoolChecks(agencyExpired), style: 'sectionText', fontSize: isVariation ? minimumFontSize : undefined }]  }
    ];
    !(typeof agencyExpired === 'boolean' && agencyExpired) && terminationDeets.push(
      { text: 'Written termination to previous agent(s) given?', style: 'tableFieldLabel', fontSize: isVariation ? minimumFontSize : undefined },
      generateCheckboxRows(Object.values(getPreviousNoticeOptions(confirmPrevious ?? false)), notice?[previousNoticeOptions[notice]]:[], 1 )
    );

    restContent.push({
      unbreakable: true,
      stack: spaceStackLinesSideEffect(terminationDeets)
    });
  }
  return itemSubsection({
    subsectionTitle: 'Previous agents',
    titleLineContent: { yesNoBox: true, currentVal: enable, trueLabel: 'Yes', falseLabel: 'No' },
    subsectionContent: spaceStackLinesSideEffect(restContent),
    unbreakable: false,
    bookmark: ['subsection-previous-agents', 'field_focus_prevAgentEnable', 'field_focus_prevAgentConfirm'],
    isVariation
  });
}

function agentAuthoritySection(agentAuthority: boolean | undefined, isVariation?: boolean) {
  const content = [];
  content.push({
    unbreakable: true,
    margin: [0, fieldsSpacing, 0, 0],
    ...twoValueCheckbox({
      label: {
        text: 'Is the Agent authorised to accept offers on behalf of the Vendor?',
        style: 'tableFieldLabel',
        fontSize: isVariation ? minimumFontSize : undefined
      },
      trueLabel: boolYesNoOpts.true,
      falseLabel: boolYesNoOpts.false,
      currentVal: agentAuthority
    })
  });

  return itemSubsection({
    subsectionTitle: 'Agent authority',
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(content),
    unbreakable: false,
    bookmark: ['subsection-agent-authority', 'field_focus_agentAuthority'],
    isVariation
  });
}
