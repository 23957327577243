import { ContractSettlementEnables } from '@property-folders/contract';

export * from '../util/pdfgen/constants';

export const UPDATING_FLASH_TIME = 300;
export const UPDATED_PERSIST_TIME = 3000;
export const COMPONENT_COMMIT_DELAY = 2000;

export const NOT_SET_STR = '-';

export const AGENCY_AGREEMENT_EXPIRY_REMINDER_DAYS = 14;

export const contractSettlementDefaultEnables: ContractSettlementEnables = {
  date: true,
  contract: true,
  condition: true,
  other: false
};

export const emailLogoLimits = {
  x: 512,
  y: 512
};