import { FormTypes, PartyCategory } from '@property-folders/common/yjs-schema/property/form';
import { AccompanyingObjects, ManifestData } from '../sync';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { IParsedLegalDescription, Hundred } from '@property-folders/common/client-api/IParsedLegalDescription';
import { CustomFieldType, DivisionType, FormOrderState, FormOrderType, GstFreeReason, SaleMethod } from './enum';
import { OfferType, OfferValues } from './OfferContractState';
import {
  PurchaserEditableDocumentStatus,
  PurchaserSubmittedDocumentStatus,
  SettingsOfferType
} from '../yjs-schema/purchaser-portal';
import { z } from 'zod';
import { ExtractedField, PageDimension } from '@property-folders/common/util/pdf/types';
import { FieldPosition } from '../rest/signing';
import { PathType } from '../yjs-schema/model';
import { MarketingTemplate } from '../yjs-schema/entity-settings';
import { IFileRelatedData } from '@property-folders/common/offline/fileStorage';

export interface MaterialisedProperty {
  meta: TransactionMetaData;
  data: MaterialisedPropertyData;
  alternativeRoots?: {
    [dataRootKey: string]: {
      meta: TransactionMetaData;
      data: MaterialisedPropertyData;
    }
  }
}

export enum LandType {
  Unknown = 0,
  Residential = 1,
  Rural = 2,
  Commercial = 3
}

export enum FolderType {
  Property = 'Property',
  Document = 'Document',
  MyFile = 'MyFile',
}

export function formSigningStateToString(formSigningState: FormSigningState) {
  switch (formSigningState) {
    case FormSigningState.None:
      return 'Draft';

    case FormSigningState.Configuring:
      return 'Configuring';

    case FormSigningState.OutForSigningPendingUpload:
    case FormSigningState.SignedPendingDistribution:
    case FormSigningState.OutForSigningPendingServerProcessing:
    case FormSigningState.OutForSigning:
      return 'Out for signing';

    case FormSigningState.SignedPendingUpload:
    case FormSigningState.Signed:
      return 'Signed';
  }
}

export enum FormSigningState {
  /**
   * The document is being prepared
   */
  None = 0,
  /**
   * The document is prepared and signing settings are being selected
   */
  Configuring = 1,
  /**
   * The document is processed by the server and ready to be signed
   */
  OutForSigning = 2,
  /**
   * Document is fully executed
   */
  Signed = 3,
  /**
   * Document is nearly fully executed, pending pdf upload (client -> server)
   */
  SignedPendingUpload = 4,
  /**
   * Document is nearly fully executed, pending final pdf build/distribution (server side)
   */
  SignedPendingDistribution = 5,
  /**
   * The document+manifest is ready to be uploaded
   */
  OutForSigningPendingUpload = 6,
  /**
   * The document+manifest is being uploaded/processed by the server
   */
  OutForSigningPendingServerProcessing = 7
}

export const SigningStates = new Set<FormSigningState>([FormSigningState.OutForSigning, FormSigningState.OutForSigningPendingUpload, FormSigningState.OutForSigningPendingServerProcessing]);
export const SignedStates = new Set<FormSigningState>([FormSigningState.Signed, FormSigningState.SignedPendingUpload, FormSigningState.SignedPendingDistribution]);

export enum SigningPartyType {
  SignOnline = 1, // AKA Email
  SignInPerson = 2, // AKA Hosted
  SignWet = 3, // AKA Paper
  SignOnlineSms = 4
}

export enum SignerProxyType {
  Self = 0,
  Auctioneer = 1,
  Salesperson = 2,
  Proxy = 3
}

export const SigningPartyTypeOptions = {
  [SigningPartyType.SignOnline]: 'Email link',
  [SigningPartyType.SignInPerson]: 'On salesperson\'s screen',
  [SigningPartyType.SignWet]: 'On paper',
  [SigningPartyType.SignOnlineSms]: 'SMS link'
};

export const SignerProxyAuthorityOptions = {
  [SignerProxyType.Self]: '',
  [SignerProxyType.Auctioneer]: 'Auctioneer',
  [SignerProxyType.Salesperson]: 'Salesperson',
  [SignerProxyType.Proxy]: 'Proxy'
};
export const SignerProxyAuthorityDropdownOptions = [
  { name: `${SignerProxyType.Self}`, label: 'Represented by Self' },
  { name: `${SignerProxyType.Auctioneer}`, label: 'Represented by Auctioneer' },
  { name: `${SignerProxyType.Salesperson}`, label: 'Represented by Salesperson' }
  //{ name: `${SignerProxyType.Proxy}`, label: 'Represented by Proxy' } // Not currently available
];

export const SignerProxyAuthorityRoleOptions = {
  [SignerProxyType.Self]: '',
  [SignerProxyType.Auctioneer]: 'Auctioneer',
  [SignerProxyType.Salesperson]: 'Authorised Salesperson',
  [SignerProxyType.Proxy]: 'Proxy'
};

export const RemoteSigningTypes = [
  SigningPartyType.SignOnline,
  SigningPartyType.SignOnlineSms
];

export type SigningPartyMessageConfig = {
  content?: string;
};

export enum SigningPartyVerificationType {
  Sms = 1
}

export type SigningPartyVerificationConfig = {
  type?: SigningPartyVerificationType;
};

export enum SigningPartySourceType {
  Error = 0,
  Salesperson = 1,
  /**
   * Use the full legal name and {email,phone}1 fields
   */
  Vendor = 2,
  /**
   * Use the {name,email,phone}1 fields
   */
  VendorFirstParty = 3,
  /**
   * Use the {name,email,phone}2 fields
   */
  VendorSecondParty = 4,
  // Purchaser is similar to vendor in the data model, uses same fields based on name
  Purchaser = 5,
  PurchaserFirstParty = 6,
  PurchaserSecondParty = 7,
  Other = 8,
  OtherFirstParty = 9,
  OtherSecondParty = 10
}

export const SigningPartySourcePurchasers = [
  SigningPartySourceType.Purchaser,
  SigningPartySourceType.PurchaserFirstParty,
  SigningPartySourceType.PurchaserSecondParty
];

export const SigningPartySourceVendors = [
  SigningPartySourceType.Vendor,
  SigningPartySourceType.VendorFirstParty,
  SigningPartySourceType.VendorSecondParty
];
export const ValidAuctioneerSigners = [
  ...SigningPartySourcePurchasers,
  ...SigningPartySourceVendors
];

export const sourceTypeGroup = new Map<SigningPartySourceType, SigningPartySourceType>([
  [SigningPartySourceType.Vendor, SigningPartySourceType.Vendor],
  [SigningPartySourceType.VendorFirstParty, SigningPartySourceType.Vendor],
  [SigningPartySourceType.VendorSecondParty, SigningPartySourceType.Vendor],
  [SigningPartySourceType.Purchaser, SigningPartySourceType.Purchaser],
  [SigningPartySourceType.PurchaserFirstParty, SigningPartySourceType.Purchaser],
  [SigningPartySourceType.PurchaserSecondParty, SigningPartySourceType.Purchaser],
  [SigningPartySourceType.Other, SigningPartySourceType.Other],
  [SigningPartySourceType.OtherFirstParty, SigningPartySourceType.Other],
  [SigningPartySourceType.OtherSecondParty, SigningPartySourceType.Other],
  [SigningPartySourceType.Salesperson, SigningPartySourceType.Salesperson]
]);

export function SigningPartySourceTypeToString(party: SigningPartySourceType) {
  switch (party) {
    case SigningPartySourceType.Salesperson:
      return 'Salesperson';
    case SigningPartySourceType.Vendor:
    case SigningPartySourceType.VendorFirstParty:
    case SigningPartySourceType.VendorSecondParty:
      return 'Vendor';
    case SigningPartySourceType.Purchaser:
    case SigningPartySourceType.PurchaserFirstParty:
    case SigningPartySourceType.PurchaserSecondParty:
      return 'Purchaser';
    case SigningPartySourceType.Other:
    case SigningPartySourceType.OtherFirstParty:
    case SigningPartySourceType.OtherSecondParty:
      return 'other';
    case SigningPartySourceType.Error:
    default:
      return 'Error';

  }
}

export function SigningPartySourceTypeToStringPrint(party: SigningPartySourceType) {
  let result = SigningPartySourceTypeToString(party);
  if (result === 'Salesperson') {
    result = 'Agent';
  }
  return result;
}

export interface SourceRepresentationLevel {
  accessKey: 'dualPartyPseudoLevel' | 'singlePartyRep' | 'namedExecutors' | 'legalRepresentatives' | string,
  position: number,
  itemId?: string
}

export type SigningPartySource = {
  type: SigningPartySourceType;
  overrideType?: SigningPartySourceType,
  originalType?: string;
  representationHierarchy: SourceRepresentationLevel[]
  id: string;
  sublineageId?: string;
  agencySalesPersonId?: string | number,
  agencyId?: string | number;
  isPopulated: boolean;
  isRemoving?: boolean;
  added?: boolean;
  proxy?: { // Used only for audit at this stage, Currently stored in the main party object, rightly or wrongly
    proxyAuthority: Exclude<SignerProxyType, SignerProxyType.Self>
    proxyName: string,
    proxyFor: string,
    proxyForPhone?: string,
    proxyForEmail?: string
  }
  // they're an reaforms agent, but they could be signing on behalf of another agency.
  // which makes them an authorised representative. e.g. EPF team certifying form 1
  isAuthRep?: boolean;
  _optimisation?: {
    name: string,
    email?: string;
    phone?: string;
    searchable: string;
    agentId?: number;
  }
};

export type SigningProxySnapshotAddon = {
  proxy?: {
    proxyAuthority: SignerProxyType
    proxyName: string,
    proxyPhone?: string,
    proxyEmail?: string,
    originalIdentifier: SigningPartySnapshot['signingPartyIdentifier']
  }
};

export type SigningPartySnapshot = SigningProxySnapshotAddon & {
  name: string;
  signingPartyIdentifier: string;
  partyIdentifier: string;
  originalPartySourceId: string;
  filledSigningPhrase?: string;
  email: string;
  phone: string;
  addressSingleLine?: string;
  addressSingleLine_parts?: AddressParts;
  company?: string;
  abn?: string;
  linkedSalespersonId?: string | number;
  isPrimary?: boolean;

  originalPartyId?: string;
};

export enum SigningPartyDeclineType {
  /**
   * The party doesn't agree with T&Cs
   */
  TermsAndConditions = 1,
  /**
   * The party is fine with T&Cs but doesn't want to sign the document.
   */
  Document = 2
}

export type SigningPartyOrderSettings = {
  order: number;
  /**
   * true = the system should automatically commence this party's signing,
   * once the previous party within the category has signed
   * false = the agent will trigger this party's signing
   */
  auto: boolean;
};

export type PartyDistributionState = ({
  type: 'email'
  lastDistributionEmailEvent?: EmailEvent;
  lastDistributionEmailEventTimestamp?: number;
  lastDistributionEmailId?: string | undefined;
} | {
  type: 'sms'
} | {
  type: 'manual'
  declaredDate: string;
} | {
  type: 'delayed'
} | {
  type: 'manualWaiting'
});

export type SigningParty = {
  id: string;
  source: SigningPartySource;
  colour: string;
  type: SigningPartyType;
  proxyAuthority?: SignerProxyType;
  proxyEmail?: string
  proxyPhone?: string
  proxyName?: string
  proxyLinkedId?: number
  typeHostParty?: string | number;
  typeHostComposite?: string; // Signals signing type, and if on screen, on whose screen, and what proxy type (eg
                              // auctioneer) a party has appointed
  // typeHostComposite_display?: string // What to display over the select element in case it gets nothing
  message?: SigningPartyMessageConfig;
  verification?: SigningPartyVerificationConfig;
  verificationDefaultCleared?: boolean
  commsPrefPortal?: CommunicationPreference
  snapshot?: SigningPartySnapshot;
  /**
   * epoch ms
   */
  signedTimestamp?: number;
  declineType?: SigningPartyDeclineType;
  /**
   * If the party declines, store their reason/message here
   */
  declineReason?: string;
  declineTimestamp?: number;
  linkLastSent?: number;
  lastAccessedTimestamp?: number;
  tandcAgreedTimestamp?: number;
  lastEmailEvent?: EmailEvent;
  lastEmailEventTimestamp?: number;
  lastEmailId?: string | undefined;
  distributionState?: PartyDistributionState
  locked?: boolean;
  serverAcceptPending?: boolean;
  lastEditedTimestamp?: number;
  signedPdf?: FileRef;
  portal?: boolean;
  notificationBlock?: boolean;
  noVoidNotify?: boolean;
  signingOrderBlocked?: boolean;
  /**
   * if set, this configures signing settings for the party
   */
  signingOrderSettings?: SigningPartyOrderSettings;
  /**
   * If true, this party is not part of signing and should be ignored.
   * else, this party is party of signing.
   * e.g. They are initially added as a signing party for an uploaded document, however,
   * No signature/initials field for them to fill out is included in the signing session.
   * Essentially, there is no action for them to perform, so they're not involved.
   * This might happen if the agent just wants to stamp their personal details onto the document.
   */
  ignoreForSigning?: boolean;
};

export enum FileRefServerState {
  Waiting,
  Processing,
  Available
}

const FileRefZ = z.object({
  id: z.string(),
  /**
   * e.g.
   * application/pdf
   * image/png
   * image/jpeg
   */
  contentType: z.string()
});

export type FileRef = z.infer<typeof FileRefZ>;
export type FileFormRef = {
  /**
   * form instance id
   *
   * If this used to be a FileRef, then the id also happens to be a file id.
   * This is because historically the form/file were created together and the same id was used for both.
   */
  id: string;
  /**
   * If not set, default should be treated as FormCode.UploadedDocument.
   * But, if a match couldn't be found, it's worth expanding the search to other types of form,
   * particularly uploaded form 1s
   */
  code?: FormCodeUnion;
  /**
   * @deprecated
   * if this is set, then this used to be a FileRef.
   */
  contentType?: string
};

export enum SigningSessionFieldType {
  Signature = 1,
  Initials = 2,
  Text = 3,
  Check = 4,
  Radio = 5
}

export enum SigningSessionSubType {
  None = 0,
  /**
   * Render relevant information with the thing.
   * e.g. Render timestamp/name underneath the Signature
   */
  RenderInfoInline = 1
}

export enum NotificationSuppressTarget {
  void = 'void',
  documentDistribution = 'docDist'
}

export const SigningSessionFieldZ = z.object({
  id: z.string(),
  partyId: z.string(),
  type: z.nativeEnum(SigningSessionFieldType),
  subtype: z.optional(z.nativeEnum(SigningSessionSubType)),
  file: z.optional(FileRefZ),
  isWetSigned: z.optional(z.coerce.boolean()),
  text: z.optional(z.string()),
  timestamp: z.optional(z.number()),
  smsSecret: z.optional(z.string()),
  customFieldId: z.optional(z.string()),
  inlineTimestampPosition: z.optional(z.object({ x: z.number(), y: z.number() })),
  originalFieldName: z.optional(z.string())
});

export type SigningSessionField = z.infer<typeof SigningSessionFieldZ>;

export type SigningInitiator = {
  // agent id
  id: number;
  // agent name
  name: string;
  email: string;
  timeZone?: string;
  entity: {
    id: number;
    name: string;
    uuid?: string;
  },
  notifyOnSigning?: boolean | undefined;
  portalUser?: {
    portalType: 'purchaser';
    portalId: string;
    portalUserId: string;
    name: string;
    email: string;
  }
};

export type SigningSessionOrderItemParty = {
  order: number;
  partyId: string;
  auto: boolean;
  state: 'inactive' | 'ready' | 'active';
};

export type SigningSessionOrderItem = {
  id: string;
  type: PartyCategory;
  dependsOn?: PartyCategory;
  dependsOnCount: number;
  /**
   * true = auto-set active to true when the condition is met.
   * false = the agent will manually activate by clicking a button.
   */
  auto: boolean;
  /**
   * active = the signing sessions for this type should be active, or activated if they aren't already active
   * ready = the signing sessions for this type are ready and should be manually activated
   * inactive = the signing sessions for this type are not active, but could be manually activated
   */
  state: 'inactive' | 'ready' | 'active';
  readyNotifiedAt?: number;
  parties?: SigningSessionOrderItemParty[]
};

export type SigningSessionOrderParty = {
  order: number;
  partyId: string;
  // party id this party depends on
  dependsOn?: string;
  auto: boolean;
  state: 'inactive' | 'ready' | 'active';
  readyNotifiedAt?: number;
};

export type PropertyMetaAccompanying = { [Property in keyof AccompanyingObjects]: FileRef };

export type SigningSessionCCItem = {
  id: string;
  name?: string;
  distributionState?: PartyDistributionState;
} & (
  | { type: 'phone', phone: string; }
  | { type: 'email', email: string; }
);

export type SigningSession = {
  id: string;
  file: FileRef;
  pdfInfo?: {
    extractedFields: ExtractedField[];
    pageDimensions: PageDimension[];
  };
  intermediateFiles?: FileRef[];
  associatedFiles?: PropertyMetaAccompanying
  completedFile?: FileRef[] | FileRef;
  certifiedDocument?: FileRef;
  completedTime?: number;
  fields: SigningSessionField[];
  headline?: string;
  initiator: SigningInitiator; // This is a denormalisation, but considering the existing code refactor could lead to a
                               // fair bit of work, this will be the eventual source of truth after the session
                               // actually starts
  timestamp?: number;
  /**
   * If set, then the signing session is scoped only to those types specified.
   */
  partySourceTypeRestriction?: SigningPartySourceType[];
  contractFromPortal?: { // When this is set, partySourceTypeRestriction above should probably be unset
    partySourceTypeRestriction?: SigningPartySourceType[];
  }, // We need a hint so when we void it, it doesn't become a draft document, it should go back having the
     // partySourceTypeRestriction
  /**
   * If specified, signing should proceed in the order specified by the dependencies.
   * When the server decides that all within a type have signed, it will activate anything depending on them.
   * Alternatively, a user may manually set state to active
   */
  signingOrder?: SigningSessionOrderItem[];
  delayAcknowledged?: boolean
  delayFulfilled?: boolean
  /**
   * For flat ordered party signing
   */
  partyOrder?: SigningSessionOrderParty[];
  // if specified and nonempty, these are the page indexes to split off into separate pdfs after signing.
  // e.g. if user wants to "download" the result, we would split into pdfs and return a zip archive of them all
  splits?: {
    page: number,
    name: string,
    sourceId: string
  }[];
};

export const SigningGeneralConfigSchema = z.object({
  subject: z.string(),
  message: z.string()
});

export type SigningGeneralConfig = z.infer<typeof SigningGeneralConfigSchema>;

export type ServedByAgent = {
  agentUuid: string,
  entityUuid?: string
};

export interface LastServedContactSnapshot {
  id?: string // Should be excluded during purchaser version comparisons
  name: string;
  email?: string;
  phone?: string;
  address: string;
  isPrimary?: boolean
}

export type ServeStateRecipient = {
  type: 'purchaser';
  portalCommsPref?: CommunicationPreference;
  id: string;
  signingSessionId: string;
  lastServedSigningSessionId?: string;
  name: string;
  email?: string;
  phone?: string;
  address: string;
  timestamp?: number;
  contractDate?: string;
  // if originating from a specific contract
  originatingForm?: { id: string, code: FormCodeUnion, sublineageId?: string, source: string };
  // if not set, make an assumption based on presence of email field.
  serveMode?: 'email' | 'download' | 'sms'
  servedBy?: ServedByAgent;
  lastServedPurchasersHash?: string;
  lastServedContactSnapshot?: LastServedContactSnapshot[]
  forceReServe?: boolean,
  reServed?: boolean;
  manuallyServed?: {
    signedCopy?: FileRef;
    timestamp: number;
  };
  viewedOnline?: {
    timestamp: number;
  };
  servedByEmail?: {
    timestamp: number;
  };
  servedBySms?: {
    timestamp: number;
  };
  portal?: {
    portalId: string;
    portalUserId: string;
    documentId: string;
  };
  lastEmailEvent?: {
    type: EmailEvent;
    timestamp: number;
    emailId?: string;
  };
  downloaded?: {
    timestamp: number;
    online: boolean;
  };
  servedInstances?: {
    [k: string]: Omit<ServeStateRecipient, 'servedInstances'>;
  };
  publishedDocumentId?: string;
  autoServe?: boolean;
};

export type SigningOrderSettingsItem = {
  id: string;
  type: PartyCategory;
  order: number;
  /**
   * true = the system should automatically commence this party type's signing
   * when the previous set of parties has signed. in practice it'll probably just relate to the link-sending part.
   * false = the agent will click a button to commence signing for this party type
   */
  auto: boolean;
  /**
   * true = parties within this group will also have an order.
   * signing will be triggered one at a time.
   * false/unset = no ordering of the parties in this group.
   * signing will be triggered for all at the same time.
   */
  partyOrder?: boolean;
};

export type CustomFieldCommonTextProps = {
  fontColour?: string;
  fontSize: number;
  lineHeight?: number;
  fontFamily?: string;
};

export type CustomFieldCommonBgProps = {
  bg?: boolean;
  bgColour?: string;
};

export type CustomFieldCommonBorderProps = {
  border?: boolean;
  borderColour?: string;
  borderWidth?: number;
};

export type CustomFieldBase = {id: string; position: FieldPosition;};

export type PredefinedTextCustomField<T> = {
  type: T;
} & CustomFieldBase & CustomFieldCommonTextProps & CustomFieldCommonBgProps;

export type CustomFieldConfiguration = { id: string, position: FieldPosition } & (
  | ({ type: CustomFieldType.signature, partyId: string } & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.initials, partyId: string } & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.timestamp, partyId: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.name, partyId: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.authority, partyId: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.email, partyId: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.phone, partyId: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.company, partyId: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.abn, partyId: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.address, partyId: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.saleAddress } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.saleTitle } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.text, text: string } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  // no fontFamily
  | ({ type: CustomFieldType.checkmark, checkmark: string, fontColour?: string; fontSize: number; lineHeight?: number; } & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.purchaserName } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.purchaserAddress } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.contractDate } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.remoteText, multiline?: boolean, text?: string, required?: boolean } & CustomFieldCommonTextProps & CustomFieldCommonBgProps)
  | ({ type: CustomFieldType.remoteCheck, group: string, label: string, on?: boolean } & CustomFieldCommonTextProps)
  | ({ type: CustomFieldType.remoteRadio, group: string, label: string, on?: boolean } & CustomFieldCommonTextProps)
  | PredefinedTextCustomField<CustomFieldType.settlement>
  | PredefinedTextCustomField<CustomFieldType.salesperson>
  | PredefinedTextCustomField<CustomFieldType.purchasePrice>
  | PredefinedTextCustomField<CustomFieldType.primaryPurchaser>
  | PredefinedTextCustomField<CustomFieldType.primaryVendor>
  | PredefinedTextCustomField<CustomFieldType.proposedAllotments>
  | PredefinedTextCustomField<CustomFieldType.propertySummary>
  );

export type FormInstanceSigning = {
  state?: FormSigningState;
  general?: SigningGeneralConfig;
  parties?: SigningParty[];
  session?: SigningSession;
  sessionInitiator?: SigningInitiator; // This is a denormalisation, and this will no longer be the source of truth
                                       // once the session is initiated
  /**
   * @deprecated probably. Use formStates.{FAMILY}.recipients instead.
   */
  recipients?: ServeStateRecipient[];
  isGenerating?: boolean;
  useSigningOrder?: boolean;
  /**
   * 1/undefined = original implementation, category order
   * 2 = flat party order
   */
  signingOrderVersion?: number;
  signingOrderSettings?: SigningOrderSettingsItem[];
  customiseScreen?: 'fields';
  customFields?: CustomFieldConfiguration[]
  partiesForced?: boolean;
  customFieldDefaults?: Record<string, any>;
  instanceAutoForm1?: boolean;
  delayFullAutoDistribute?: boolean;
  // preSigningCustomisationBaseFile?: FileRef;
  // if signing field customisation is taking place on a smart/greatform then this snapshot is useful for tracking the base file and signing fields
  preSigningCustomisationSessionSnapshot?: SigningSession;
  ccBackup?: SigningSessionCCItem[];
};

export type FormInstanceOrder = {
  type: FormOrderType;
  state: FormOrderState;
  filler?: {
    entityId: number;
    system: 'EPF';
  };
  job?: {
    id: number;
    requestedAtMs?: number;
    preparedAtMs?: number;
    preparedByAgentId?: number;
    fillerManagedSigning?: boolean;
  }
  source?: {
    sourcePropertyId: string;
    sourceFormId: string;
    sourceFormCode: string;
    sourceEnvelopeId?: string;
    sourceEntityName?: string;
  }
  pdfPreview?: FileRef;
  info: {
    type: 'epf-form1',
    useInstantSearches?: boolean;
    waitForSearches?: boolean;
    whoCompletesVq: 'SalesPerson' | 'EckermannPropertyForms' | 'Vendor';
    whoArrangesSigning: 'SalesPerson' | 'EckermannPropertyForms';
    whoPays: 'Agency' | 'Vendor' | 'Provider',
    whoServes: 'Agency' | 'EckermannPropertyForms';
  }
  notificationEmail: string;
};

export type SignedForm = {
  id: string[],
  name: string,
  timestamp: string
};

type AnnexureCommon = {
  label?: string;
  noEditRemove?: boolean;
  preserveFile?: boolean;
  uploadType?: UploadType;
  // true = it is managed outside the annexure section, no removal.
  managed?: boolean;
  // when the annexure is deleted, the specified path in the ydoc should also be deleted
  binding?: {
    root: string;
    path: PathType;
  }
};
type AnnexureVariations =
  | FileFormRef & { name?: string; linkedFormId?: string; coversheetText?: string; linkedTitle?: string, replacingId?: string }
  | { id: string; folderScopeRef?: string; _removedMarker: true; _replacedBy?: string }
  | { id: string; folderScopeRef?: true; _restoredMarker: true; }
  | { id: string; folderScopeRef: string };
export type Annexure = AnnexureCommon & AnnexureVariations;

/**
 * Primary/Family Form Codes
 */
export enum FormCode {
  RSAA_SalesAgencyAgreement = 'RSAA',
  RSC_ContractOfSale = 'RSC',
  Form1 = 'form1',
  LicenceToOccupy = 'licenceToOccupy',
  OfferToPurchase = 'offerToPurchase',
  VendorQuestionnaire = 'vendorQuestionnaire',
  StorySheet = 'storySheet',

  OpenInspection = 'openInspection',
  Auction = 'auction',
  PurchaserManagement = 'purchaseMgmt',
  OfferManagement = 'offerMgmt',
  ContractManagement = 'contractMgmt',
  AllDocuments = 'allDocs',
  UploadedDocument = 'UPLOADED'
}

/**
 * Addendums/Variations/Subsequents
 */
export enum ExtraFormCode {
  CRSSA_SalesAgencyAgreementSubsequent = 'crssa',
  AAV_SalesAgencyAgreementVariation = 'AAV',
  SCV_ContractOfSaleVariation = 'SCV',
  SCT_ContractOfSaleTermination = 'SCT',
  SCTE_ContractOfSaleTerminationExternal = 'SCTE',
  Form1Upload = 'form1upload'
}

export type FormCodeUnion =
  | FormCode
  | ExtraFormCode
  | SubscriptionFormCode;

export type FormInstanceCustomParties = {
  /**
   * list of all the selected parties.
   */
  parties?: {
    id: string;
    type: SigningPartySourceType;
    category: PartyCategory;
    sublineageId?: string;
  }[];
};

export type UploadPdfAction = {
  action: 'rotate_cw' | 'rotate_ccw' | 'delete',
  pageIndex: number
};

export enum UploadType {
  // title division plan
  PropertyPlan = 'plan',
  FormR3 = 'form_r3',
  // LSSA title details
  TitleDetails = 'title',
  ComparableSales = 'comparable',
  DwellingPlan = 'dwelling_plan',
  DwellingSpecification = 'dwelling_spec',
  MarketingSchedule = 'marketing_schedule'
}

export enum CoverSheetMode {
  Standard = 0,
  Custom = 1,
  None = 2
}

export type CoverSheetCustomisation = {
  mode?: CoverSheetMode;
  custom?: {
    file?: FileFormRef;
  }
};

export type FormInstance = {
  id: string
  formCode: FormCodeUnion
  expiry?: string // This should not be set until all parties have signed. Rules to set actual date, uh???
  /**
   * ms since epoch
   */
  created?: number;
  /**
   * ms since epoch
   */
  modified?: number;
  dataModified?: number;
  signing?: FormInstanceSigning;
  annexures?: Annexure[];
  /**
   * i.e. legacy
   */
  subscription?: {
    // given a document id and network access, this can be converted into formID/version/filename
    documentId: number;
    fileName: SubscriptionFormCode | string;
    formId: number;
  }
  /**
   * 3rd party completion
   */
  order?: FormInstanceOrder;
  archived?: boolean;
  /**
   * note: if upload && upload.v !== 2 then migrate it
   */
  upload?: FormInstanceUploadProps;
  /**
   * External document, placeholder only
   */
  external?: boolean;
  onSignedNavigation?: { formCode: FormCodeUnion, formId: string, sublineageId?: string },
  cover?: CoverSheetCustomisation;
  cc?: SigningSessionCCItem[];
};

export type FormInstanceUploadPropsV1 = {
  /**
   * FileRef property
   * @deprecated
   */
  id: string;
  /**
   * FileRef property
   * @deprecated;
   */
  contentType: string;
  v?: 1;
  name: string;
  uploader?: Pick<AgencySalesperson, 'id'> & Partial<Omit<AgencySalesperson, 'id'>>;
  actions?: UploadPdfAction[];
  /**
   * @deprecated
   */
  type?: UploadType;
  linkedTitle?: string;
  unsigned?: { vendor?: boolean, agent?: boolean };
  editableAsCoverFor?: FormCodeUnion[];
};
export type FormInstanceUploadFileItem = FileRef & {
  uploader?: Pick<AgencySalesperson, 'id'> & Partial<Omit<AgencySalesperson, 'id'>>;
  created?: number;
  actions?: UploadPdfAction[];
  size?: number;
  name: string;
  // if true: show name between two lines on a new page
  cover?: boolean;
};
export type FormInstanceUploadPropsV2 = {
  v: 2;
  name: string;
  linkedTitle?: string;
  unsigned?: { vendor?: boolean, agent?: boolean };
  files?: FormInstanceUploadFileItem[];
  editableAsCoverFor?: FormCodeUnion[]
};
export type FormInstanceUploadProps =
  | FormInstanceUploadPropsV1
  | FormInstanceUploadPropsV2;

/**
 * instanceList is expected to be in signing completion date order, ascending
 */
export type InstanceHistory = {
  instanceList: FormInstance[],
  data: { [instanceFileId: string]: MaterialisedPropertyData },
  signingTimelines: FormInstance[][],
  latestExpiry?: Date | boolean,
  latestSignedId?: string | null
};

export type FolderScopedAnnexure = FileRef & {
  sourceStaticId?: string // static ID, means not a UUID. Used to identify unique annexures tied to a specific part of
                          // the document (eg division plan)
  name?: string;
  familyScope?: FormCodeUnion[]
  coversheetText?: string
};

export type FormFamilyState = {
  instances?: FormInstance[],
  clauseChildId?: string;
  terminatedTime?: number;
  terminatedExternal?: boolean;
  terminationConfirmed?: boolean; // ie the server confirms termination, like how only the server can set a form
                                  // instance as signed
  label?: string;
  archived?: boolean;
  /**
   * Mostly for form 1 purchasers - any significant form 1 updates should be sent.
   * e.g un-archiving an already signed form 1 may result in re-serving if another form 1 was served in the interim
   */
  recipients?: ServeStateRecipient[];
  /**
   * Because we now have a cancel for download recipients, there's this implication that for
   * recipients we manually add, that we'd like to keep them to serve them a different way. We store
   * them seperately here, as if they're in the recipients list, it is used in such a way that they
   * have already been served
   */
  unservedRecipients?: ServeStateRecipient[];
};

export type FormStates = {
  [formFamily: string]: FormFamilyState
};

export type OwningEntity = {
  id: number;
  name: string;
};

export type CreatorAgent = {
  id: number;
  name: string;
  /***
   * ms since epoch
   * e.g. Date.now()
   */
  timestamp: number;
};

export type CreatorPortalUser = {
  id: string;
  name: string;
  timestampMs: number;
};

export type CachedParty = {
  canonicalId: string;
  images: {
    signature?: FileRef;
    initials?: FileRef;
  }
};

export type TandCAcceptEvent = {
  type: SigningPartyType;
  timestampMs: number;
  name: string;
  phone?: string;
  email?: string;
  partyId?: string;
  salespersonId?: number | string;
};

export type OfferDocumentDesc = {
  id?: string, // List item id
  purchaserId: string,
  type: OfferType,
  offerSummary: OfferValues
  purchaserSigning: {
    name: string,
    sourceId: string,
    signedAt: string
  }[],
  vendorSigning: {
    name: string,
    sourceId: string,
    signedAt: string
  }[],
  status: PurchaserSubmittedDocumentStatus | PurchaserEditableDocumentStatus,
  statusAtMs: number,
  statusChangedBy: string,
  isFavourite: boolean
};

export type AgentVisibleContracts = ({
  ydocReference: any
  formStates: FormStates // This is a copy
} & OfferDocumentDesc)[];

export enum PurchaserProcessStepType {
  Register = 'anonymousRegistration',
  AccessR3 = 'r3',
  AccessForm1 = 'form1',
  AccessContract = 'exampleContract',
  AccessBrochure = 'brochure',
  SubmitLetterOfOffer = 'submitLetterOfOffer',
  SubmitContract = 'submitContract'

}

export enum PurchaserProcessAction {
  visible = 'visible',
  disabled = 'disabled',
  created = 'created',
  viewed = 'viewed',
  invited = 'invited',
  registered = 'registered',
  unverified = 'unverified'
}

export type PurchaserProcessMeta = {
  action: PurchaserProcessAction,
  type?: SettingsOfferType,
  date?: number
};

export type ProspectivePurchaserGroup = {
  /**
   * equivalent to portalUserId
   */
  id: string,
  purchaserParties: ProspectivePurchaserParty[],
  primaryPurchaser?: string,
  subDocumentsAgencyCanAccessAndEdit: AgentVisibleContracts // Needs to be kept up to date as referenced ydoc changes
  submittedOffers: ({ // Values here should Write Once Read Many
    ydocReference: any
    data: MaterialisedPropertyData
    metadata: TransactionMetaData
  } & OfferDocumentDesc)[],
  meta?: {
    registration: PurchaserProcessMeta[],
    form1: PurchaserProcessMeta[],
    exampleContract: PurchaserProcessMeta[],
    offerDocument: PurchaserProcessMeta[],
  }
};

export type ProspectivePurchaserParty = {
  id: string
  fullLegalName: string,
  address: string,
  address_parts: any,
  email: string,
  phone: string,
  fullParty: PurchaserParty
};

export type OfferMeta = {
  vendors: {
    id: string
    fullLegalName: string,
    address: string,
    address_parts: any,
    email: string,
    phone: string
  }[],
  prospectivePurchasers: ProspectivePurchaserGroup[],
  portalId: string,
  draftOffersIncrementArray: number[];
};

export type InlineFile = {
  id: string;
  contentType: string;
  data: number[];
  meta: {
    formId: string,
    signingSessionId?: string,
    agentId: number,
    formCode: string,
  }
};

export enum FileTrackState {
  NotUploaded = 0,
  Uploading = 1, // Not uploaded and uploading are functionally the same. We might not be able to declare we are uploading without access to the ydoc from the triggering function, which could be timed
  Available = 2,
  ServerProcessing = 3
}

export const StillUploadingStates = new Set<FileTrackState>([
  FileTrackState.NotUploaded,
  FileTrackState.Uploading
]);

export const NotYetAvailableStates = new Set<FileTrackState>([
  FileTrackState.NotUploaded,
  FileTrackState.Uploading,
  FileTrackState.ServerProcessing
]);

export type ReuploadRequest = {
  ver: number,
  claimedVer?: number,
  // The file may have lost its manifest data, so we're instructing to replace it prior to upload
  manifest?: ManifestData
  related?: IFileRelatedData
};

export type FileIdState = {
  state: FileTrackState
  version?: string
  eTag?: string // When available on the server
};

export type FileTrackDocRoot = {
  files?: {
    [fileId: string]: FileIdState
  },
  clientReup2?: {
    [fileId: string]: ReuploadRequest
  }
};

export type TransactionMetaData = {
  archived?: {
    archivedByAgentId?: number;
    archivedDate?: string; // The MySQL instance will set its own time based on when it recieves this change???
  }
  sublineageRoots?: string[] // ydoc root keys. Meta will be {key}_meta or something
  formStates?: FormStates;
  folderAnnexures?: FolderScopedAnnexure[]
  /**
   * Entity (Agency) which owns the record
   */
  entity?: OwningEntity;
  /**
   * Agent who created the record
   */
  creator?: CreatorAgent;
  /**
   * Portal user who created the record
   */
  portalCreator?: CreatorPortalUser;
  isPortalMember?: boolean // Flag if present, will allow processing of purchaser detail changes, see
                           // journal-event/process-record/property. Should not be present on an Agent's property
                           // folder ydoc
  partyCache?: CachedParty[];
  tandcAccept?: TandCAcceptEvent[];
  /**
   * List of main data model party IDs
   */
  previouslySignedParties?: string[]; //
  createdUtc?: string;
  offerManagement?: OfferMeta;
  parentId?: string;
  /**
   * List of files that should be "uploaded" inline with yjs updates.
   * Server-side will write them as needed, and remove the inline file from the ydoc.
   * It's up to specific use-cases to make use of this mechanism (e.g. hosted signing signature upload).
   */
  inlineFiles?: InlineFile[];
  /**
   * indicates the ydoc was prefilled on creation.
   * audit uses this to provide better messaging
   */
  prefilled?: boolean;
  /**
   * @deprecated use formStates.UPLOADED.*
   * (where 'UPLOADED' is FormCode.UploadedDocument)
   */
  uploadedDocuments?: UploadedDocument[];
  sharing?: TransactionSharing
} & (
  { documentId: number; folderType: FolderType.Document }
  | { folderType: FolderType.Property }
  | { folderType: FolderType.MyFile }
  );

export type TransactionSharingQueueItem = {
  id: string;
  sid: number;
  headline: string;
  subtitle?: string;
  canShare: boolean;
  entityId?: number;
  teamId?: number;
  agentId?: number;
};

export type TransactionSharingExcludeItem = {
  id: string;
  sid: number;
  entityId?: number;
  teamId?: number;
  agentId?: number;
};

export type TransactionSharing = {
  lastSync?: number;
  createQueue?: TransactionSharingQueueItem[];
  // do not grant access to the things specified here
  createExclusions?: TransactionSharingExcludeItem[];
  creating?: boolean;
  serverChangesPending?: boolean;
};

export type UploadedDocument = FormFamilyState & FileRef & {
  name?: string;
  uploader?: AgencySalesperson
};

export type AgencySalesperson = {
  id: string;
  linkedSalespersonId?: string | number;
  name: string;
  email: string;
  phone: string;
  // if true, server-side sharing processing needs to occur.
  sharingChangesPending?: boolean;
};

export type Agent = {
  id: string;
  linkedEntityId?: string | number;
  profileName?: string;
  company: string;
  abn: string;
  rla: string;
  salesp?: AgencySalesperson[];
  address?: string;
  phone?: string;
  email?: string;
  form1?: {
    company?: string;
    address?: string;
    serviceFaxOrEmail?: string;
    serviceAddress?: string;
    serviceAddressIsRla?: boolean;
  },
  // if true, server-side sharing processing needs to occur.
  sharingChangesPending?: boolean;
};

export const AddressPartsSchema = z.object({
  Country: z.optional(z.string()),
  Postcode: z.optional(z.string()),
  Suburb: z.optional(z.string()),
  State: z.optional(z.string()),
  Latitude: z.optional(z.string()),
  Longitude: z.optional(z.string()),
  LotNumber: z.optional(z.string()),
  UnitNumber: z.optional(z.string()),
  LevelNumber: z.optional(z.string()),
  StreetNumber: z.optional(z.string()),
  StreetName: z.optional(z.string()),
  UnitType: z.optional(z.string())
});

export type AddressParts = z.infer<typeof AddressPartsSchema>;

export type SaleAddress = {
  id: string;
  lga: string;
  hundred: string;
  irrigationArea: string;
  streetAddr: string;
  subStateAndPost: string;
  streetAddr_parts?: AddressParts;
  fromLssa: boolean;
  integrationId?: string;
  integrationName?: string;
  integrationUrl?: string;
  // Not 100% sure we should be setting or using this, as this is only being set by a dropdown.
  // If we make the address selector offline capable, maybe.
  suburb: string;
  gnaf?: string;
  additionalDesc?: string
  useAdditionalDescAsLegalDesc?: boolean;
};

export type SaleSubTitle = {
  id: string;
  lot: string;
  plan: string;
  lotid: string;
  planid: string;
  portionType: TitleInclusionState
  hundreds?: Hundred[]
  area?: string
};

export type SaleTitle = {
  id: string;
  title: string;
  isWhole: boolean;
  isNone?: boolean;
  linkedAddresses?: string[];
  fromLssa: boolean;
  subTitles?: SaleSubTitle[];
  propertyCacheId?: string;
  valuations?: string[]
  descriptionOfLand?: IParsedLegalDescription;
  legalLandDescription?: string;
};

export type ItemiserItem = {
  id: string
  itemDesc?: string
  itemCost?: string
};

export type PressBudgetType = {
  enable?: boolean
  strike?: boolean
  additional?: boolean
  start?: string
  startOther?: string
  expenditure?: string | number
  expPeriod?: string
  endAgency?: boolean
  otherEnd?: string
};

export type FixedSearchFees = {
  epfFee?: { enable: boolean };
  councilSearch?: {
    enable?: boolean;
    itemCost?: number
  }
};

export type BenefitItem = ItemiserItem & { itemSource?: string, itemRecipient?: string };
export type DisclosureOfBenefitsType = {
  enable?: boolean;
  template?: { id: string, name: string };
  benefits?: BenefitItem[];
};

/**
 * @deprecated - use new marketing templates
 */
export type MarketingTemplateOld = {
  enable?: boolean,
  id: number,
  name: string,
  agentName?: string,
  title?: string,
  subTitle?: string,
  items?: MarketingTemplateItemOld[]
};

/**
 * @deprecated - use new marketing templates
 */
export type MarketingTemplateItemOld = ItemiserItem & {
  enable?: boolean
  readonly?: boolean
  section?: string
};

export enum ProfessionalFeeMode {
  'fixed' = 0,
  'commission' = 1
}

export enum FeeCalcMechanism {
  'Error' = 0,
  'None' = 1,
  'Set' = 2,
  'Discounted' = 4,
  'Range' = 3,
  'Scale' = 5
}

export enum FixedFeePayableWhen {
  'SaleEffected' = 1,
  'SaleEffectedOrNot' = 2
}

export type FeeScaleItemType = {
  id?: string
  amount?: string,
  limit?: string
};

export type FeeScaleType = {
  levels?: FeeScaleItemType[]
};

export type FeeEnables = {
  set?: boolean, // Would like to use the enums, but that would make these keys numbers, and we can't use numbers in paths
  range?: boolean,
  discount?: boolean,
  scale?: boolean,
  none?: boolean
};

export type ProfessionalFeeType = {
  fixed?: string
  fixedUpper?: string
  fixedDiscount?: string
  fixedScale: FeeScaleType
  commis?: string
  commisUpper?: string
  commisDiscount?: string
  commisScale: FeeScaleType
  fixedMode?: FeeCalcMechanism
  commissionMode?: FeeCalcMechanism
  fixedPayableWhen?: FixedFeePayableWhen
  enabledModes?: {
    fixedFee?: FeeEnables,
    commis?: FeeEnables
  }
};
export type UpliftType = {
  displaySuppressed?: boolean
  enable?: boolean
  mode?: ProfessionalFeeMode
  strike?: boolean
  thresh?: number | string
  fixed?: number | string
  portion?: number | string
  percentageOrFixed?: boolean
};
export type AdminFeeType = {
  enable?: boolean;
  strike?: boolean;
  templatePrice?: string;
  setPrice?: string;
};

export type SaleDetailsType = {
  agentEstPrc?: string | number;
  vendorPrc?: string | number;
  advertPrc?: string | number;
  advertPrcUpper?: string | number;
  advertRange?: string;
  saleMethod?: SaleMethod;
  saleMethodOther?: string;
  settlement?: string | number;
};
export type PaymentTermsType = {
  enable: boolean;
  strike?: boolean;
  marketingPaid: boolean;
  method: string;
  rendFreq: string;
  actualTerms: string;
  surcharge: string | number;
  methodOther: string;
};

export type GenericFieldMapType = { [k: string]: string | number | boolean | undefined };

export enum PartyType {
  Error = '',
  Individual = 'indi',
  Corporation = 'corp',
  ExecutorNatural = 'executorNatural',
  ExecutorCompany = 'executorCompany',
  ExecutorJoint = 'executorJoint',
  AdministratorNatural = 'adminNatural',
  AdministratorCompany = 'adminCompany',
  AdministratorJoint = 'AdministratorJoint',
  MortgageeNatural = 'mortgageNatural',
  MortgageeCompany = 'mortgageCompany',
  MortgageeJoint = 'mortgageJoint',
}

export const companyTypes = [
  PartyType.Corporation,
  PartyType.ExecutorCompany,
  PartyType.AdministratorCompany,
  PartyType.MortgageeCompany
];

export const jointTypes = [PartyType.MortgageeJoint, PartyType.ExecutorJoint, PartyType.AdministratorJoint];
export const jointPartyPlurals = {
  [PartyType.MortgageeJoint]: 'Mortgagees',
  [PartyType.ExecutorJoint]: 'Executors',
  [PartyType.AdministratorJoint]: 'Administrators'
};
export const jointPartyNouns = {
  [PartyType.MortgageeJoint]: 'Mortgagee',
  [PartyType.ExecutorJoint]: 'Executor',
  [PartyType.AdministratorJoint]: 'Administrator'
};

export enum JointPartyType {
  ExecutorNatural = 'executorNatural',
  ExecutorCompany = 'executorCompany',
  AdministratorNatural = 'adminNatural',
  AdministratorCompany = 'adminCompany',
  MortgageeNatural = 'mortgageNatural',
  MortgageeCompany = 'mortgageCompany',
}

export const jointGroupingPermitted = {
  [PartyType.ExecutorJoint]: [PartyType.ExecutorNatural, PartyType.ExecutorCompany],
  [PartyType.AdministratorJoint]: [PartyType.AdministratorNatural, PartyType.AdministratorCompany],
  [PartyType.MortgageeJoint]: [PartyType.MortgageeNatural, PartyType.MortgageeCompany]
};

const jointGroupNatural = [PartyType.ExecutorNatural, PartyType.AdministratorNatural, PartyType.MortgageeNatural];
const jointGroupCompany = [PartyType.ExecutorCompany, PartyType.AdministratorCompany, PartyType.MortgageeCompany];

export const jointGroupingEquivalenceKey = {
  [PartyType.ExecutorNatural]: jointGroupNatural,
  [PartyType.AdministratorNatural]: jointGroupNatural,
  [PartyType.MortgageeNatural]: jointGroupNatural,
  [PartyType.ExecutorCompany]: jointGroupCompany,
  [PartyType.AdministratorCompany]: jointGroupCompany,
  [PartyType.MortgageeCompany]: jointGroupCompany
};

export enum PurchaserType {
  Individual = 'indi',
  Corporation = 'corp'
}

export enum SigningAuthorityType {
  sole = 'sole',
  self = 'self',
  guardian = 'guardian',
  directorSecretary = 'directorSecretary',
  directors2 = 'directors2',
  authRep = 'authRep',
  attorney = 'attorney',
  attorneyJoint = 'attorneyJoint',
  guardianJoint = 'guardianJoint',
}

export const multiRepAuthority = [SigningAuthorityType.attorneyJoint, SigningAuthorityType.guardianJoint];

export const DUAL_PARTY_AUTHORITY = [
  SigningAuthorityType.directorSecretary,
  SigningAuthorityType.directors2
];

export interface AuthorityPartyLegalRep {
  id: string,
  name?: string;
  email?: string;
  phone?: string;
  abn?: string;
}

export type AuthorityParty = {
  partyType?: PartyType;
  authority?: SigningAuthorityType;

  id: string;
  integrationId?: string;
  integrationName?: string;
  integrationUrl?: string;
  fullLegalName?: string;
  onBehalfOf?: string;
  addressSingleLine?: string;
  addressSingleLine_parts?: AddressParts;
  abn?: string;

  personName1?: string;
  email1?: string;
  phone1?: string;
  personName2?: string;
  email2?: string;
  phone2?: string;

  inTrust?: boolean;
  primarySubcontact?: number;
  primarySubcontactId?: string;

  linkedAddress?: string[];
  fromLssa?: boolean;
  legalRepresentatives?: AuthorityPartyLegalRep[]
};

export type VendorParty = AuthorityParty & {
  registeredOnTitle?: boolean;
  probateGranted?: boolean;
  probateByDate?: string;
  addrSameAsSale?: boolean;
  linkedAddresses?: string[];
  mortgageNumber?: string;
};

export type VendorPartyJoint = Omit<VendorParty,
  'fullLegalName' | 'authority'
  | 'email1' | 'email2'
  | 'personName1' | 'personName2' |
  'phone1' | 'phone2' |
  'abn'> & {
  partyType: PartyType.AdministratorJoint | PartyType.ExecutorJoint | PartyType.MortgageeJoint;
  primaryNamedExecutor?: string;
  namedExecutors?: Omit<AuthorityParty, 'onBehalfOf' | 'inTrust'>[];
};

export type PurchaserParty = AuthorityParty & {
  addrSameAsPrimary?: boolean
};

export type OtherContactParty = AuthorityParty & {
  overridePartyCategory?: SigningPartySourceType;
  originalType?: string;
  // used to deduplicate agent-list results from the API if they've already been added as a contact
  unlinkedAgentId?: number;
};

type DepositPayAtStrings = 'afterCooling' | 'immediate' | 'coolingWaived' | 'other';

export type ContractPriceType = {
  /**
   * Existing residential premises which is input taxed?
   * true = GST N/A
   * false = More questions
   */
  inputTaxed?: boolean;
  /**
   * Is the sale a taxable supply?
   * true = margin scheme question
   * false = the sale is GST-free questions
   */
  taxableSupply?: boolean;
  /**
   * Does the margin scheme apply?
   * true = margin scheme questions
   * false = no more questions - include GST amount in GST payable at item 5
   */
  marginApplied?: boolean;
  /**
   * Margin is included in Amount Payable?
   * true = do not include GST payable
   * false = include in GST payable once GST amount is determined
   */
  gstIncluded?: boolean;
  gstFreeReason?: GstFreeReason;
  purchasePrice?: string;
  purchaseGst?: string;
  deposit?: string;
  depositPayAt?: DepositPayAtStrings;
  depositDateOther?: string;
};
export type ContractSettlementEnables = Partial<{
  date: boolean
  contract: boolean
  condition: boolean
  other: boolean
}>;
export type ContractSettlementType = Partial<{
  _auctionSettlementTimeTriggered?: boolean // Special wizard only type
  onDate?: boolean;
  date?: string;
  onContract?: boolean;
  onContractDays?: number;
  onCondition?: boolean;
  onConditionDays?: number;
  other?: boolean
  otherText?: string
  /**
   * @deprecated - cannot specify text, just enable/disable it
   */
  afterCondition?: string;
  enabledModes?: ContractSettlementEnables
}>;

export type ContractScheduleType = {
  noForm1NoCoolMatters?: string;
};

export type ContractOtherType = {
  enable?: boolean;
  otherText?: string
};

export type FinancedProperty = { id: string, addrSingleLine: string; enable?: boolean };

export type ContractSpecialType = {
  hideSection?: boolean
  hideFinance?: boolean
  financeRequired?: boolean;
  financePermitted?: boolean;
  financeDeadline?: string;
  financeDeadlineTime?: string;
  financeAmount?: string | number;
  financeInterestRate?: string | number;
  financeProperties?: FinancedProperty[]
  hideSaleRequired?: boolean
  purchaserSaleRequired?: boolean;
  purchaserSalePermitted?: boolean;
  saleSettleAlsoRequired?: boolean;
  saleSettleUnconditional?: boolean;
  salePropertySingleLine?: string;
  salePropertyListedForSale?: boolean;
  purchaserSaleContractDeadline?: string;
  purchaserSaleSettlementDeadline?: string;
  purchaserSaleMinimumPrice?: string | number;
  propertyNotPartOfSecurity?: boolean
};

export type Inclusions = {
  simple?: string[];
  cccDetail?: string;
  cccEnable?: boolean;
};

export type Exclusions = {
  simple?: string[];
};

export type AgencyAgreementTerms = {
  duration: number | string;
  start: boolean; // True means starts on day of agreement, false is other, ie startOther
  startOther: string;
};

export enum PoolComplyState {
  unknown = 0,
  nonComply = 1,
  complyPre93 = 2,
  complyPost93 = 3
}

export enum ContractTerminationDepositReturn {
  Other = 0,
  ReleaseToVendor = 1,
  ReturnToPurchaser = 2,
  Split = 3
}

export enum EpfAutoOrdering {
  Later = 0,
  WithoutInstant = 1,
  WithInstant = 2
}

export enum TitleInclusionState {
  portion = 0,
  whole = 1,
  none = 2
}

export type PoolCompliance = {
  present?: boolean;
  complyState?: PoolComplyState;
  nonComplyWorks?: string;
};

export type ComparableAddress = {
  addrLine: string,
  soldPrc: number | string,
  soldDate: string;
};

/**
 * Portion of Land Details
 */
export type TitleDivision = {
  /**
   * Is it a proposed torrens or community division?
   */
  depositChoice?: DivisionType;
  /**
   * Has Development Authorisation been obtained?
   */
  hasDevelopmentAuthorisation?: boolean;
  /**
   * Is the answer unknown
   */
  _hasDevelopmentAuthorisation?: boolean;
  /**
   * What is the Deposit of Plan due date under the Contract going to be?
   */
  planDueDate?: string;
  /**
   * [ ] 12 months after the date of Contract
   */
  planDue12?: boolean;
  /**
   * Date or "12 months after the Contract Date"
   */
  planDueText?: string;
  /**
   * Should clauses be automatically managed by the system?
   */
  autoClause?: boolean;
  /**
   * Arbitrary text to identify the lots involved
   */
  proposedLots?: string;
  /**
   * @deprecated use `plan` instead.
   */
  proposedPlanRef?: { [prefixedFormFamilyCode: string]: string }
  /**
   * Reference to the plan file
   */
  plan?: FileRef;
};

/**
 * Dwelling Details (Portion of Land)
 */
export type PortionOfLandDwellingDetails = {
  /**
   * Is the Vendor providing vacant land to the Purchaser at Settlement? Yes / No / Unknown
   */
  vacantLand?: boolean;
  /**
   * Is the answer 'unknown'?
   */
  _vacantLand?: boolean;

  /**
   * Is there demolition required? Yes / No / Unknown
   */
  demolitionRequired?: boolean;
  /**
   * Is the answer 'unknown'?
   */
  _demolitionRequired?: boolean;

  /**
   * Is the Purchaser required to enter into a Building Contract with a particular Builder prior to Settlement? Yes /
   * No / Unknown
   */
  purchaserWillBuild?: boolean;
  /**
   * Is the answer 'unknown'?
   */
  _purchaserWillBuild?: boolean;

  /**
   * Who is the Builder?
   */
  purchaserBuilderName?: string;

  /**
   * What date do they need to enter into the Building Contract by?
   */
  buildContractDueDate?: string;
  buildContractDue12?: boolean;
  buildContractDueText?: string;

  /**
   * Is the Vendor building a new dwelling on the land for the Purchaser prior to Settlement? Yes/No/Unknown
   */
  vendorWillBuild?: boolean;
  /**
   * Is the answer 'unknown'?
   */
  _vendorWillBuild?: boolean;

  /**
   * Has Planning Consent been obtained? Yes / No / Unknown
   */
  vendorHasPlanningConsent?: boolean;
  /**
   * Is the answer 'unknown'?
   */
  _vendorHasPlanningConsent?: boolean;

  /**
   * Has Development Approval been obtained? Yes / No / Unknown
   */
  vendorHasDevelopmentApproval?: boolean;
  /**
   * Is the answer 'unknown'?
   */
  _vendorHasDevelopmentApproval?: boolean;

  /**
   * Upload a copy of the dwelling plans [upload]
   */
  vendorPlan?: FileRef;
  /**
   * Upload a copy of the dwelling specifications [upload]
   */
  vendorSpecification?: FileRef;

  /**
   * track EL quoting stuff
   */
  el?: {
    /**
     * Indicates if the agent wants a quote after SAA execution
     */
    queued?: boolean;
    /**
     * The timestamp at which the system sent a quote request to EL.
     */
    requested?: number;
    /**
     * Track state at the time the request was sent.
     */
    requestedState?: string;
  }
};

export type FamilyClauses = {
  id: string,
  formFamily: (keyof typeof FormTypes),
  clauses: Clause[]
};

export type ClauseFamilies = FamilyClauses[];

export type ContractTermination = {
  depositOption?: ContractTerminationDepositReturn
  splitVendorReleaseAmt?: number
  splitPurchaserReturnAmt?: number
  otherTerms?: string

};

export type TenantAgreement = {
  id?: string
  tenantName?: string
  manageAgent?: string
  start?: string
  end?: string
  rentalValue?: number
  period?: string
  bondEnable?: boolean
  bondAmt?: number
  adviseTenant?: boolean
  saleContTenant?: boolean
  type?: string
};

export type LegacyTenancy = {
  tenantEnable?: boolean,
  migrationLinkingTenantId?: string
};

export type InlineAnnexureRef = {
  [prefixedFormCode: string]: string
};

export type MarketingFeesOptions = {
  noFees?: boolean,
  externalFeeScheduleAnnexureRef?: InlineAnnexureRef,
  denormalisedTotal?: number
  denormalisedTotalValidation?: number
};

export type AuctionFeeSwitches = {
  applicable?: boolean,
  strike?: boolean,
  feeDesc?: string
};

export type MaterialisedPropertyData = {
  id: string;
  transactionType?: string;
  headline?: string;
  // authorised representative,
  // e.g. EPF certifying on behalf of agency. field exists only on auth rep copy of data.
  needsAuthRep?: boolean;
  authRep?: Agent[];
  agent?: Agent[];
  agentAuthority?: boolean;
  assocAgents?: Agent[];
  authAssoc?: boolean;
  saleAddrs?: SaleAddress[];
  saleTitles?: SaleTitle[];
  compareAddrs: ComparableAddress[]
  disableCompareAlreadyProvided: boolean;
  primaryVendor?: string;
  primaryPurchaser?: string;
  vendors?: VendorParty[];
  purchasers?: PurchaserParty[];
  otherContacts?: OtherContactParty[];
  sale?: SaleDetailsType;
  comparableSales?: {
    annexureRef?: InlineAnnexureRef
  }
  marketingTemplate?: MarketingTemplate | MarketingTemplateOld;
  marketingFees?: (ItemiserItem | MarketingTemplateItemOld)[];
  marketingFeesOptions?: MarketingFeesOptions
  searchFees?: ItemiserItem[];
  saaTenant?: LegacyTenancy
  tenantsCollect?: TenantAgreement[]
  fixedSearchFees?: FixedSearchFees;
  pressBudget?: PressBudgetType;
  benefits?: DisclosureOfBenefitsType;
  professFee?: ProfessionalFeeType;
  uplift?: UpliftType;
  adminFee?: AdminFeeType;
  auctionFee?: string;
  auctionFeeSwitches?: AuctionFeeSwitches;
  gstWithholdEnable?: boolean
  cgtPerson?: string
  payTerm?: PaymentTermsType;
  vendorWorksEnable?: boolean;
  vendorWorks?: string;
  /**
   * Is the vendor registered / required to be registered for gst?
   * i.e. Do we need to fill out the gst section?
   * true = More questions
   * false = GST N/A
   */
  vendorGst?: boolean;
  saaGstUnknowns?: {
    vendorGst?: boolean,
    inputTaxed?: boolean
  }
  contractPrice?: ContractPriceType;
  contractSettlement?: ContractSettlementType;
  contractSchedule?: ContractScheduleType;
  contractSpecial?: ContractSpecialType;
  contractOther?: ContractOtherType;
  contractTermination?: ContractTermination;
  offerAdditionalConditions?: string;
  landType?: LandType | string;
  chattels?: Inclusions;
  exclusions?: Exclusions;
  agency?: AgencyAgreementTerms;
  pool?: PoolCompliance;
  titleDivision?: TitleDivision;
  dwelling?: PortionOfLandDwellingDetails;
  proposedLots?: string;
  clauses?: Clause[];
  clausesByFamily?: ClauseFamilies;
  encroach?: string;
  encroachEnable?: boolean;
  notiWorks?: string;
  notiWorksEnable?: boolean;
  specialTerms?: string;
  specialTermsEnable?: boolean;
  form1AndSearches?: Form1AndSearches;
  transactionFee?: {
    whoPays: VendorAgent;
    enabled: boolean;
    waiveFee?: boolean;
    searchCreditAmountCents?: number;
    transactionFeeAmountCents?: number;
  },
  coolingOff?: CoolingOffType
  allVendorsSignForm1?: boolean;
};

export type CoolingOffRight = 'yes' | 'no' | 'waived';

export type CoolingOffType = {
  purchaserRight?: CoolingOffRight;
  startDate?: number;
  endDate?: number;
};

export type VendorAgent = 'vendor' | 'agent';
export type Form1SearchParty = 'eckermanns' | 'agent' | 'vendor';
export type Form1AndSearches = {
  whoPays: VendorAgent;
  whoSearches?: Form1SearchParty;
  epfOrderingAutomatic?: EpfAutoOrdering
};

export enum EmailEvent {
  open = 'open',
  bounce = 'bounce',
  click = 'click',
  processed = 'processed',
  delivered = 'delivered',
  dropped = 'dropped',
  spamreport = 'spamreport',
  deferred = 'deferred'
}

export type CustomClause = {
  id: string;
  text: string;
  title: string;
};

export type ImportedClause = CustomClause & {
  importId: string;
  required: boolean;
  canEdit: boolean;
  editing: boolean;
  hash: string;
  condition?: string;
  managedBy?: string;
};

export type Clause = CustomClause | ImportedClause;

export enum CommunicationPreference {
  Email = 'email',
  Phone = 'phone'
}

