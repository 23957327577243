import * as fflate from 'fflate';
import { ContentType } from '@property-folders/contract';
import { byMapperFn } from '../sortComparison';
import { PDFDocument } from '@cantoo/pdf-lib';
import { StringUtil } from '../string';

export async function split(pdf: Blob, splits: { page: number, name: string }[]) {
  if (!splits.length) {
    return pdf;
  }

  const contents: fflate.Zippable = {};
  const sequence = [...splits]
    .sort(byMapperFn(x => x.page));
  const doc = await PDFDocument.load(await pdf.arrayBuffer());
  doc.getForm().flatten();

  if (sequence[0].page !== 0) {
    sequence.splice(0, 0, { page: 0, name: 'first' });
  }

  for (let i = 0; i < sequence.length; i++) {
    const { page, name } = sequence[i];
    const next = sequence[i+1];
    const fileName = StringUtil.sanitiseFileName(`${name}.pdf`);
    if (next) {
      contents[fileName] = await copyPageRange(doc, page, next.page);
    } else {
      contents[fileName] = await copyPageRange(doc, page, doc.getPageCount());
    }
  }

  return new Blob([fflate.zipSync(contents)], { type: ContentType.Zip });
}

export async function extractRange(pdf: Blob, start: number, exclusiveEnd?: number) {
  const doc = await PDFDocument.load(await pdf.arrayBuffer());
  doc.getForm().flatten();

  const end = typeof exclusiveEnd === 'number'
    ? exclusiveEnd
    : doc.getPageCount();

  return new Blob([await copyPageRange(doc, start, end)], { type: ContentType.Pdf });
}

async function copyPageRange(src: PDFDocument, start: number, exclusiveEnd: number) {
  const dst = await PDFDocument.create();
  const count = exclusiveEnd - start;
  const indices = [...new Array(count).keys()].map(i => i + start);

  const pages = await dst.copyPages(src, indices);
  pages.map(page => dst.addPage(page));
  return await dst.save();
}
